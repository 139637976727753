<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      <span>{{ $t("message.links") }}</span>
      <LinkedButton route="LinkAdd" type="primary" icon="el-icon-plus">{{
        $t("message.add")
      }}</LinkedButton>
    </div>
    <div class="table-wrap">
      <el-table
        border
        size="mini"
        :data="data"
        style="width: 100%"
        class="table"
      >
        <el-table-column type="index"> </el-table-column>
        <el-table-column :label="$t(`message.name`)" prop="name">
        </el-table-column>
        <el-table-column :label="$t(`message.url`)" prop="url">
        </el-table-column>
        <el-table-column width="120" align="center">
          <template slot-scope="scope">
            <LinkedButton
              route="LinkEdit"
              :params="{ id: scope.row.id }"
              type="primary"
              icon="el-icon-edit"
            />
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteLink(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "LinkList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    loadLinks() {
      this.loading = true;
      this.$http
        .get("link")
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteLink(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`link/${id}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadLinks();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  mounted() {
    this.loadLinks();
  },
};
</script>

<style scoped lang="scss">
.district-select {
  margin-left: 20px;
}
.table-wrap {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
