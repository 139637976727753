<template>
  <el-card v-loading.lock="loading">
    <div class="header" slot="header">
      <div>
        <p>
          {{ this.$t("message.user") }}: <span>{{ username }}</span>
        </p>
      </div>

      <div>
        <h1>{{ this.$t("message.changeUserPassword") }}</h1>
      </div>
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="20" :md="18" :lg="12" :xl="10">
          <el-form
            size="small"
            label-position="top"
            :model="changePasswordForm"
            status-icon
            :rules="passwordFormRules"
            ref="changePasswordForm"
            label-width="120px"
            class="demo-changePasswordForm"
          >
            <el-form-item :label="this.$t('message.password')" prop="password">
              <el-input
                type="password"
                v-model="changePasswordForm.password"
                autocomplete="off"
                show-password
                ref="password"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              :label="this.$t('message.confirmPassword')"
              prop="confirmPassword"
            >
              <el-input
                type="password"
                v-model="changePasswordForm.confirmPassword"
                autocomplete="off"
                show-password
                @keyup.enter.native="changePassword"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-refresh"
                type="info"
                @click="generatePassword"
                >{{ $t("message.generatePassword") }}</el-button
              >
              <el-button
                icon="el-icon-copy-document"
                type="success"
                @click="copy"
                >{{ $t("message.copy") }}</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="changePassword">
                {{ $t("message.save") }}
              </el-button>
              <BackButton>{{ $t("message.back") }}</BackButton>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "UserChangePasswordForm",
  components: {
    BackButton,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("message.requiredField")));
      } else {
        if (this.changePasswordForm.confirmPassword !== "") {
          this.$refs.changePasswordForm.validateField("confirmPassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("message.requiredField")));
      } else if (value !== this.changePasswordForm.password) {
        callback(new Error(this.$t("message.twoInputsNotMatch")));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      username: "",
      changePasswordForm: {
        password: "",
        confirmPassword: "",
      },
      passwordFormRules: {
        password: [{ validator: validatePass, trigger: "change" }],
        confirmPassword: [{ validator: validatePass2, trigger: "change" }],
      },
    };
  },
  methods: {
    copy() {
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          let password = this.$refs.password.$el.querySelector("input");
          password.select();
          // console.log(password.value);
          document.execCommand("copy");
        }
      });
    },
    generatePassword() {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.changePasswordForm.password = retVal;
      this.changePasswordForm.confirmPassword = retVal;
    },
    changePassword() {
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .put(
              `sektor-user/${this.$route.params.id}`,
              {},
              {
                params: {
                  password: this.changePasswordForm.password,
                },
              }
            )
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(response.data.message, this.$t("message.error"), {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.username = this.$route.query.username;
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  > div {
    > p {
      > span {
        font-weight: bolder;
        color: #2a3098;
      }
    }
  }
}
</style>
