<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.sectorAdmins") }}
      <div>
        <el-button
          class="header-item"
          size="mini"
          @click="downloadXls"
          type="primary"
          icon="el-icon-document"
          >{{ $t("message.download") }}</el-button
        >
        <linked-button
          type="success"
          route="SectorAdminAdd"
          icon="el-icon-plus"
          >{{ $t("message.add") }}</linked-button
        >
      </div>
    </div>
    <div class="table-wrap">
      <el-table
        border
        size="mini"
        :data="data"
        style="width: 100%"
        class="table"
      >
        <el-table-column type="index"> </el-table-column>
        <el-table-column
          width="150"
          :label="$t(`message.username`)"
          prop="username"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.firstName`)" prop="firstName">
        </el-table-column>
        <el-table-column :label="$t(`message.lastName`)" prop="lastName">
        </el-table-column>
        <el-table-column :label="$t(`message.phoneNumber`)" prop="phoneNumber">
        </el-table-column>
        <el-table-column
          :label="$t(`message.sectorNumber`)"
          prop="sektorNumber"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.region`)" prop="regionName">
        </el-table-column>
        <el-table-column width="160" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <LinkedButton
                route="UserChangePassword"
                :params="{ id: scope.row.id }"
                :query="{ username: scope.row.username }"
                icon="el-icon-key"
              />
              <LinkedButton
                route="SectorAdminEdit"
                :params="{ id: scope.row.id }"
                type="primary"
                icon="el-icon-edit"
              />
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="deleteSectorAdmin(scope.row.id)"
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-main> </el-main>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
import axios from "axios";
export default {
  name: "SectorAdminList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    async downloadXls() {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}admin/sektor-admin/excel`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          params: {},
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
      var blob = new Blob([res.data], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "sector-admins.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    loadSectorAdmins() {
      this.loading = true;
      this.$http
        .get("admin/sektor-admin", {
          params: {},
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSectorAdmin(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`admin/sektor/`, { params: { id } })
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadSectorAdmins();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  mounted() {
    this.loadSectorAdmins();
  },
};
</script>

<style scoped></style>
