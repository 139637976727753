var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-menu',{staticClass:"main-navigation",attrs:{"default-active":_vm.$route.path,"collapse":_vm.collapsed,"collapse-transition":false,"router":""}},[_vm._l((_vm.nav),function(item,index){return [(
        !item.isSubmenu &&
        item.main &&
        (item.meta.authorize.includes(_vm.role) || !item.meta.authorize.length)
      )?_c('el-menu-item',{key:index,attrs:{"index":("/dashboard/" + (item.path))}},[_c('i',{staticClass:"icon-setting",class:item.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(("message." + (item.title)))))])]):_vm._e(),(
        item.isSubmenu &&
        (item.meta.authorize.includes(_vm.role) || !item.meta.authorize.length)
      )?_c('el-submenu',{key:item.path,attrs:{"index":index.toString()}},[_c('template',{slot:"title"},[_c('i',{staticClass:"icon-setting",class:item.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(("message." + (item.title)))))])]),_vm._l((item.children),function(menu){return [(
            menu.main &&
            (item.meta.authorize.includes(_vm.role) ||
              !item.meta.authorize.length)
          )?_c('el-menu-item',{key:menu.path,attrs:{"index":("/dashboard/" + (menu.path))}},[_c('i',{staticClass:"icon-setting",class:menu.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(("message." + (item.title)))))])]):_vm._e()]})],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }