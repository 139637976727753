<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">{{ $t(`message.${title}`) }}</div>
    <el-main class="main">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="14">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <el-row :gutter="20">
              <el-col :span="8" v-if="!form.id">
                <el-form-item
                    prop="regionId"
                    :label="this.$t('message.region')"
                >
                  <el-select
                    style="width: 100%"
                    size="mini"
                    v-model="regionId"
                    :loading="regionLoading"
                    :loading-text="$t('message.loading')"
                    @change="regionChange"
                    filterable
                  >
                    <el-option
                      v-for="region in regions"
                      :label="region.name"
                      :value="region.id"
                      :key="region.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="!form.id">
                <el-form-item
                  prop="districtId"
                  :label="this.$t('message.district')"
                >
                  <el-select
                    style="width: 100%"
                    class="district-select"
                    size="mini"
                    :loading="districtLoading"
                    :loading-text="$t('message.loading')"
                    v-model="form.districtId"
                    filterable
                  >
                    <el-option
                      v-for="district in districts"
                      :label="district.name"
                      :value="district.id"
                      :key="district.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="firstName"
                  :label="this.$t('message.firstName')"
                >
                  <el-input
                    v-model="form.firstName"
                    :placeholder="this.$t('message.firstName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="lastName"
                  :label="this.$t('message.lastName')"
                >
                  <el-input
                    v-model="form.lastName"
                    :placeholder="this.$t('message.lastName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="phoneNumber"
                  :label="this.$t('message.phoneNumber')"
                >
                  <el-input
                    v-model="form.phoneNumber"
                    :placeholder="this.$t('message.phoneNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="passportSerialNumber"
                  :label="this.$t('message.passportSerialNumber')"
                >
                  <el-input
                    v-model="form.passportSerialNumber"
                    :placeholder="this.$t('message.passportSerialNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="birthDate"
                  :label="this.$t('message.birthDate')"
                >
                  <el-input
                    v-inputmask="'99.99.9999'"
                    v-model="form.birthDate"
                    :placeholder="this.$t('message.birthDate')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="sektorName"
                  :label="this.$t('message.sectorName')"
                >
                  <el-input
                    v-model="form.sektorName"
                    :placeholder="this.$t('message.sectorName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="sektorPhoneNumber"
                  :label="this.$t('message.sektorPhoneNumber')"
                >
                  <el-input
                    v-model="form.sektorPhoneNumber"
                    :placeholder="this.$t('message.sektorPhoneNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="username"
                  :label="this.$t('message.username')"
                >
                  <el-input
                    :readonly="form.id !== null"
                    v-model="form.username"
                    :placeholder="this.$t('message.username')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="!form.id">
                <el-form-item
                  prop="password"
                  :label="this.$t('message.password')"
                >
                  <el-input
                    v-model="form.password"
                    :placeholder="this.$t('message.password')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="directorFirstName"
                  :label="this.$t('message.directorFirstName')"
                >
                  <el-input
                    v-model="form.directorFirstName"
                    :placeholder="this.$t('message.directorFirstName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="directorLastName"
                  :label="this.$t('message.directorLastName')"
                >
                  <el-input
                    v-model="form.directorLastName"
                    :placeholder="this.$t('message.directorLastName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="directorMiddleName"
                  :label="this.$t('message.directorMiddleName')"
                >
                  <el-input
                    v-model="form.directorMiddleName"
                    :placeholder="this.$t('message.directorMiddleName')"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  prop="directorPhoneNumber"
                  :label="this.$t('message.sektorDirectorPhoneNumber')"
                >
                  <el-input
                    v-model="form.directorPhoneNumber"
                    :placeholder="this.$t('message.sektorDirectorPhoneNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="directorPhoneNumberSecond"
                  :label="this.$t('message.directorPhoneNumberSecond')"
                >
                  <el-input
                    v-model="form.directorPhoneNumberSecond"
                    :placeholder="this.$t('message.directorPhoneNumberSecond')"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="22">
                <el-form-item
                  prop="location"
                  :label="this.$t('message.location')"
                >
                  <el-input
                    v-model="form.location"
                    :placeholder="this.$t('message.location')"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-form-item prop="active" :label="this.$t('message.active')">
                  <el-switch v-model="form.active"></el-switch>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item>
                  <el-button type="primary" size="mini" @click="save">
                    {{ $t("message.save") }}
                  </el-button>
                  <BackButton>{{ $t("message.back") }}</BackButton>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";

export default {
  name: "SectorUserForm",
  components: { BackButton },
  data() {
    const checkDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("message.fieldRequired")));
      } else if (value && !this.$moment(value, "DD.MM.YYYY").isValid()) {
        return callback(new Error(this.$t("message.wrongDate")));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      regionLoading: false,
      districtLoading: false,
      title: "sectorAdd",
      regions: [],
      districts: [],
      regionId: null,
      form: {
        id: null,
        lastName: null,
        firstName: null,
        birthDate: null,
        phoneNumber: null,
        passportSerialNumber: null,
        districtId: null,
        username: null,
        password: "123",
        active: true,
        sektorName: null,
        directorFirstName: null,
        directorLastName: null,
        directorMiddleName: null,
        directorPhoneNumber: null,
        directorPhoneNumberSecond: null,
        sektorPhoneNumber: null,
        location: null,
      },
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        sektorName: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        districtId: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        birthDate: [
          {
            validator: checkDate,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          let form = { ...this.form };
          form.birthDate = this.$moment(form.birthDate, "DD.MM.YYYY").valueOf();

          let http;

          if (this.form.id) {
            http = this.$http.put("sektor-user", form);
          } else {
            http = this.$http.post("sektor-user", form);
          }

          http
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    regionChange() {
      this.form.districtId = null;
      this.districts = [];
      this.loadDistricts();
    },
    loadRegions() {
      this.regionLoading = true;
      this.$http
        .get("region")
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.regionLoading = false;
        });
    },
    loadDistricts() {
      this.districtLoading = true;
      this.$http
        .get("district", {
          params: {
            id: this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.districtLoading = false;
        });
    },
    getOneSector() {
      this.loading = true;
      this.$http
        .get(`sektor-user/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
          this.form.birthDate = this.$moment(data.birthDate).format(
            "DD.MM.YYYY"
          );
          this.form.password = null;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "sectorEdit";
      this.form.id = this.$route.params.id;
      this.getOneSector();
    }
    this.loadRegions();
  },
};
</script>

<style scoped lang="scss">
.main {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}
</style>