<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.commonAppeals") }}
      <linked-button
        route="CommonAppealAdd"
        icon="el-icon-plus"
        type="primary"
        >{{ $t("message.add") }}</linked-button
      >
    </div>

    <el-main>
      <div class="table-wrap">
        <el-table
          border
          size="mini"
          :data="data"
          style="width: 100%"
          class="table"
        >
          <el-table-column type="index" label="№"> </el-table-column>
          <el-table-column width="50" :label="$t(`message.id`)" prop="id">
          </el-table-column>
          <el-table-column :label="$t(`message.question`)" prop="question">
          </el-table-column>
          <el-table-column :label="$t(`message.answer`)" prop="answer">
          </el-table-column>
          <el-table-column width="120" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="deleteOne(scope.row.id)"
                type="danger"
                icon="el-icon-delete"
              ></el-button>
              <LinkedButton
                route="CommonAppealEdit"
                :params="{ id: scope.row.id }"
                type="primary"
                icon="el-icon-edit"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "CommonAppealList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  methods: {
    getAll() {
      this.loading = true;
      this.$http
        .get("common-appeal")
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteOne(id) {
      this.$confirm(
        this.$t("message.sureToDelete"),
        this.$t("message.warning"),
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`common-appeal/${id}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.getAll();
          })
          .catch(() => {
            this.$alert(
              this.$t("message.errorProcessing"),
              this.$t("message.error"),
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>

<style scoped lang="scss">
.table-wrap {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
</style>
