<template>
  <el-card v-loading.lock="loading" :body-style="{ padding: '2px' }">
    <div slot="header" class="header">
      <span class="title">{{ $t("message.appeals") }}</span>
      <div>
        <el-select
          v-if="$store.state.user.role === 'ADMIN'"
          class="header-item"
          size="mini"
          :loading="regionLoading"
          :loading-text="$t('message.loading')"
          v-model="regionId"
          :placeholder="$t('message.region')"
          @change="changeFilter('region')"
          clearable
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="
            $store.state.user.role === 'REGION_ADMIN' ||
            $store.state.user.role === 'ADMIN'
          "
          class="header-item"
          size="mini"
          :loading="districtLoading"
          :loading-text="$t('message.loading')"
          v-model="districtId"
          :placeholder="$t('message.district')"
          @change="changeFilter('district')"
          clearable
          filterable
        >
          <el-option
            v-for="district in districts"
            :label="district.name"
            :value="district.id"
            :key="district.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="header-item"
          size="mini"
          :loading="sectorLoading"
          :loading-text="$t('message.loading')"
          v-model="sectorId"
          :placeholder="$t('message.sector')"
          @change="changeFilter('sector')"
          clearable
          filterable
        >
          <el-option
            v-for="sector in sectors"
            :label="sector.sektorName"
            :value="sector.id"
            :key="sector.id"
          >
          </el-option>
        </el-select>

        <el-select
          class="header-item"
          size="mini"
          v-model="status"
          @change="changeFilter('status')"
          clearable
          :placeholder="$t('message.status')"
          filterable
        >
          <el-option
            v-for="status in statuses"
            :label="$t(`message.${status.name}`)"
            :value="status.value"
            :key="status.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          class="header-item"
          style="width: 200px"
          @change="changeFilter('date')"
          :clearable="false"
          size="mini"
          v-model="range"
          type="daterange"
          align="right"
          value-format="timestamp"
          format="dd.MM.yyyy"
          :default-time="['00:00:00', '23:59:00']"
        >
        </el-date-picker>
        <el-button
          class="header-item"
          size="mini"
          @click="downloadXls"
          type="primary"
          icon="el-icon-document"
          >{{ $t("message.download") }}</el-button
        >
      </div>
    </div>
    <div class="main">
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
        height="calc(100vh - 215px)"
        :cell-style="cellStyle"
      >
        <el-table-column type="index" :index="indexMethod"> </el-table-column>
        <el-table-column
          width="250  "
          :label="$t(`message.region`)"
          prop="regionName"
        >
        </el-table-column>
        <el-table-column
          width="250"
          :label="$t(`message.district`)"
          prop="districtName"
        >
        </el-table-column>
        <el-table-column
          width="200"
          :label="$t(`message.sector`)"
          prop="sektorName"
        >
        </el-table-column>
        <el-table-column
          width="250"
          :label="$t(`message.mahalla`)"
          prop="mahallaName"
        >
        </el-table-column>

        <!-- <el-table-column
          width="540"
          :label="$t(`message.appealType`)"
          prop="appealType"
        > -->
        <!-- </el-table-column> -->
        <el-table-column
          align="center"
          width="200"
          :label="$t(`message.status`)"
          prop="appealStatus"
          style="padding: 0; background-color: #2a3098"
        >
          <template slot-scope="scope">
            {{ $t(`message.${scope.row.appealStatus.toLowerCase()}`) }}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          :label="$t(`message.createdDate`)"
          prop="createdDate"
        >
          <template slot-scope="scope">
            {{ $moment(scope.row.createdDate).format("DD.MM.YYYY") }}
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <LinkedButton
              route="Response102"
              :params="{ id: scope.row.id }"
              type="primary"
              icon="el-icon-view"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElement"
            :page-size="data.size"
            :page-count="data.totalPage"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
            hide-on-single-page
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElement > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElement}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
import axios from "axios";
export default {
  name: "102AppealListForAdmin",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
      status: null,
      range: [
        this.$moment()
          .clone()
          .startOf("month")
          .hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
        this.$moment().hour(23).minute(59).second(59).valueOf(),
      ],
      statuses: [
        {
          name: "sended",
          value: "SENDED",
        },
        {
          name: "extended",
          value: "EXTENDED",
        },
        {
          name: "opened",
          value: "OPENED",
        },
        {
          name: "answered",
          value: "ANSWERED",
        },
        {
          name: "rejected",
          value: "REJECTED",
        },
      ],
      page: null,
      regions: [],
      regionId: null,
      regionLoading: false,
      districts: [],
      districtId: null,
      districtLoading: false,
      sectors: [],
      sectorId: null,
      sectorLoading: false,
    };
  },
  methods: {
    cellStyle({ row, columnIndex }) {
      let background = "";
      switch (row.statusColor) {
        case "GREEN":
          background = "#61ca86";
          break;
        case "YELLOW":
          background = "#e7c072";
          break;
        case "RED":
          background = "#dd6d5a";
          break;
      }
      return columnIndex === 0
        ? {
            backgroundColor: background,
            color: "#fff",
            textAlign: "center",
            fontSize: "15px",
          }
        : {};
    },
    changeFilter(type) {
      switch (type) {
        case "region":
          this.districts = [];
          this.districtId = null;
          this.sectors = [];
          this.sectorId = null;
          this.$router.replace({
            query: {
              regionId: this.regionId,
              from: this.range[0],
              to: this.range[1],
            },
          });
          if (this.regionId) {
            this.getDistricts();
          }
          break;
        case "district":
          this.sectors = [];
          this.sectorId = null;
          this.$router.replace({
            query: {
              regionId: this.regionId,
              districtId: this.districtId,
              from: this.range[0],
              to: this.range[1],
            },
          });
          if (this.districtId) {
            this.getSectors();
          }
          break;
        case "sector":
          this.$router.replace({
            query: {
              regionId: this.regionId,
              districtId: this.districtId,
              sectorId: this.sectorId,
              from: this.range[0],
              to: this.range[1],
            },
          });
          break;
        case "status":
          this.$router.replace({
            query: {
              regionId: this.regionId,
              districtId: this.districtId,
              sectorId: this.sectorId,
              status: this.status,
              from: this.range[0],
              to: this.range[1],
            },
          });
          break;
        case "date":
          this.$router.replace({
            query: {
              regionId: this.regionId,
              districtId: this.districtId,
              sectorId: this.sectorId,
              status: this.status,
              from: this.range[0],
              to: this.range[1],
            },
          });
          break;
      }
      this.getAppeals();
    },
    getRegions() {
      this.regionLoading = true;
      this.$http
        .get(`region`)
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {})
        .finally(() => {
          this.regionLoading = false;
        });
    },
    getDistricts(regionId) {
      this.districtLoading = true;
      this.$http
        .get(`district`, {
          params: {
            id: regionId ? regionId : this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {})
        .finally(() => {
          this.districtLoading = false;
        });
    },
    getSectors() {
      this.sectorLoading = true;
      this.$http
        .get(`sektor-user/byDistrict`, {
          params: {
            districtId: this.districtId,
          },
        })
        .then(({ data }) => {
          this.sectors = this.sortList(data,'sektorName');
        })
        .catch(() => {})
        .finally(() => {
          this.sectorLoading = false;
        });
    },
    getAppeals() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("admin/card102", {
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            status: this.status,
            regionId: this.regionId,
            districtId: this.districtId,
            sektorId: this.sectorId,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadXls() {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}admin/card102-to-excel`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            status: this.status,
            regionId: this.regionId,
            districtId: this.districtId,
            sektorId: this.sectorId,
          },
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
      var blob = new Blob([res.data], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        `${
          this.regionId
            ? this.regions.find((item) => item.id === this.regionId).name
            : ""
        }-${
          this.districtId
            ? this.districts.find((item) => item.id === this.districtId).name
            : ""
        }-${
          this.sectorId
            ? this.sectors.find((item) => item.id === this.sectorId).sektorName
            : ""
        }-${this.$moment(this.range[0]).format("DD/MM/YYYY")}-${this.$moment(
          this.range[1]
        ).format("DD/MM/YYYY")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$router.replace({ query: { page: val } }).catch(() => {});
      this.getAppeals();
    },
    indexMethod(index) {
      if (this.data.totalPage > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
    sortList(data, sortBy, sortedByASC = false) {
      if (sortedByASC) {
        data.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedByASC = false;
      } else {
        data.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedByASC = true;
      }
      return data;
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$store.state.user.role === "REGION_ADMIN") {
      this.getDistricts(this.$store.state.user.regionId);
    } else {
      this.getRegions();
    }
    if (this.$route.query.regionId) {
      this.regionId = Number.parseInt(this.$route.query.regionId || "", 10);
      this.getDistricts();
    }
    if (this.$route.query.districtId) {
      this.districtId = Number.parseInt(this.$route.query.districtId || "", 10);
      this.getSectors();
    }
    if (this.$route.query.sectorId) {
      this.sectorId = Number.parseInt(this.$route.query.sectorId || "", 10);
    }
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }
    if (this.$route.query.from && this.$route.query.to) {
      this.range = [
        Number.parseInt(this.$route.query.from || "", 10),
        Number.parseInt(this.$route.query.to || "", 10),
      ];
    }
    this.$nextTick(() => {
      this.getAppeals();
    });
  },
};
</script>

<style scoped lang="scss">
.header {
  justify-content: space-between;
  .header-item {
    margin-left: 10px;
  }
}
.main {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}
.pagination {
  margin-top: 10px;
}
.pagination-total {
  margin-top: 15px;
}
@media screen and (max-width: 1390px) {
  .title {
    display: none;
  }
}
</style>
