<template>
  <el-card v-loading.lock="loading">
    <div slot="header">{{$t(`message.${title}`)}}</div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="20" :md="18" :lg="12" :xl="10">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="url" :label="this.$t('message.url')">
                  <el-input
                    v-model="form.url"
                    :placeholder="this.$t('message.url')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="name" :label="this.$t('message.name')">
                  <el-input
                    v-model="form.name"
                    :placeholder="this.$t('message.name')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" align="right">
                <el-form-item>
                  <el-button type="primary" size="mini" @click="save">
                    {{ $t("message.save") }}
                  </el-button>
                  <BackButton>{{ $t("message.back") }}</BackButton>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "LinkForm",
  components: { BackButton },
  data() {
    return {
      loading: false,
      title:"linkAdd",
      form: {
        id: null,
        url: null,
        name: null,
      },
      rules: {},
    };
  },
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("link", this.form)
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getOne() {
      this.loading = true;
      this.$http
        .get(`link/one/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      this.title = "linkEdit"
      this.getOne();
    }
  },
};
</script>

<style scoped></style>
