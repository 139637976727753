<template>
  <el-card v-loading.lock="loading" :body-style="{ padding: '2px' }">
    <div slot="header" class="header">
      <span class="title">{{ $t("message.inspectors") }}</span>
      <div>
        <el-select
          class="header-item"
          size="mini"
          :loading="regionLoading"
          :loading-text="$t('message.loading')"
          v-model="regionId"
          :placeholder="$t('message.region')"
          @change="changeFilter('region')"
          clearable
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="header-item"
          size="mini"
          :loading="districtLoading"
          :loading-text="$t('message.loading')"
          v-model="districtId"
          :placeholder="$t('message.district')"
          @change="changeFilter('district')"
          clearable
          filterable
        >
          <el-option
            v-for="district in districts"
            :label="district.name"
            :value="district.id"
            :key="district.id"
          >
          </el-option>
        </el-select>
        <linked-button
          class="header-item"
          icon="el-icon-plus"
          route="InspectorAdd"
        >
          {{ $t("message.add") }}
        </linked-button>
      </div>
    </div>
    <div class="main">
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
        height="calc(100vh - 215px)"
        :cell-style="cellStyle"
      >
        <el-table-column align="center" type="index" :index="indexMethod">
        </el-table-column>
        <el-table-column
          min-width="250"
          :label="$t(`message.fio`)"
          prop="firstname"
        >
          <template slot-scope="scope">
            {{
              `${scope.row.lastname || ""} ${scope.row.firstname || ""} ${
                scope.row.middlename || ""
              }`
            }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="240"
          :label="$t(`message.division`)"
          prop="division"
        >
          <template slot-scope="scope">
            {{ scope.row.division ? scope.row.division.name : "" }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :label="$t(`message.sector`)"
          prop="sektorName"
        >
        </el-table-column>
        <el-table-column
          width="180"
          :label="$t(`message.mahalla`)"
          prop="mahallaName"
        >
        </el-table-column>
        <el-table-column
          width="120"
          :label="$t(`message.organPhoneNumber`)"
          prop="organPhoneNumber"
        >
        </el-table-column>
        <el-table-column
          width="120"
          :label="$t(`message.phoneNumber`)"
          prop="phoneNumber"
        >
        </el-table-column>
        <el-table-column
          width="130"
          :label="$t(`message.tpnumber`)"
          prop="tpnumber"
        >
        </el-table-column>
        <el-table-column
          width="150"
          :label="$t(`message.tpphoneNumber`)"
          prop="tpphoneNumber"
        >
        </el-table-column>
        <el-table-column
          width="110"
          :label="$t(`message.averageRate`)"
          prop="avrgRate"
        >
        </el-table-column>

        <el-table-column width="200" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <LinkedButton
                route="InspectorRate"
                :params="{ id: scope.row.id }"
                type="solid"
                icon="el-icon-star-on"
              />
              <LinkedButton
                route="InspectorEdit"
                :params="{ id: scope.row.id }"
                type="primary"
                icon="el-icon-edit"
              />
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="deleteInspector(scope.row.id)"
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElements"
            :page-size="data.size"
            :page-count="data.totalPages"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
            hide-on-single-page
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElements > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElements}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "InspectorList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: {},
      status: null,
      range: [
        this.$moment()
          .clone()
          .startOf("month")
          .hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
        this.$moment().hour(23).minute(59).second(59).valueOf(),
      ],
      page: null,
      regions: [],
      regionId: null,
      regionLoading: false,
      districts: [],
      districtId: null,
      districtLoading: false,
    };
  },
  methods: {
    cellStyle({ row, columnIndex }) {
      if (columnIndex === 0) {
        return row.isFinished
          ? { backgroundColor: "#33cccc", color: "white" }
          : { backgroundColor: "#ff9999", color: "#fff" };
      }
    },
    changeFilter(type) {
      switch (type) {
        case "region":
          this.districts = [];
          this.districtId = null;
          this.$router.replace({ query: { regionId: this.regionId } });
          this.getDistricts();
          break;
        case "district":
          this.$router.replace({
            query: { regionId: this.regionId, districtId: this.districtId },
          });
      }
      this.getInspectors();
    },
    getRegions() {
      this.regionLoading = true;
      this.$http
        .get(`region`)
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {})
        .finally(() => {
          this.regionLoading = false;
        });
    },
    getDistricts() {
      this.districtLoading = true;
      this.$http
        .get(`district`, {
          params: {
            id: this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {})
        .finally(() => {
          this.districtLoading = false;
        });
    },
    getInspectors() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("inspector/all", {
          params: {
            regionId: this.regionId,
            districtId: this.districtId,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteInspector(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`inspector/${id}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.getInspectors();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$router.replace({ query: { page: val } }).catch(() => {});
      this.getInspectors();
    },
    indexMethod(index) {
      if (this.data.totalPage > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
  },
  mounted() {
    this.getRegions();
    if (this.$route.query.regionId) {
      this.regionId = Number.parseInt(this.$route.query.regionId || "", 10);
    }
    if (this.$route.query.districtId) {
      this.districtId = Number.parseInt(this.$route.query.districtId || "", 10);
      this.getDistricts();
    }
    this.getInspectors();
  },
};
</script>

<style scoped lang="scss">
.header {
  justify-content: space-between;
  .header-item {
    margin-left: 10px;
  }
}
.pagination {
  margin-top: 10px;
}
.pagination-total {
  margin-top: 15px;
}
</style>
