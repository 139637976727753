<template>
  <div class="auth-container" v-loading.fullscreen.lock="loading">
    <el-row type="flex" justify="center" align="middle" style="height: 95%">
      <el-col :xs="16" :sm="12" :md="8" :lg="6" :xl="4">
        <el-form :model="form" ref="form" :rules="formValidationRules">
          <el-form-item style="text-align: center">
            <img
              style="height: 170px"
              src="../assets/images/auth-logo.png"
              alt="smart"
            />
          </el-form-item>
          <el-form-item prop="username">
            <el-input
              v-model="form.username"
              :placeholder="$t('message.username')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              :placeholder="$t('message.password')"
              type="password"
              @keyup.enter.native="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="onSubmit">
              {{ $t("message.login") }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col class="copyright">
        &copy; IIB-smart {{ new Date().getFullYear() }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Auth",
  components: {},
  data() {
    return {
      loading: false,
      form: {
        username: null,
        password: null,
      },
      formValidationRules: {
        username: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid && !this.loading) {
          this.loading = true;
          this.retrieveAccessToken();
        }
      });
    },
    retrieveAccessToken() {
      let { username, password } = this.form;

      axios
        .post(`${process.env.VUE_APP_BASIC_URL}oauth/token`, null, {
          headers: {
            Authorization: `Basic ${process.env.VUE_APP_BASIC_AUTH_TOKEN}`,
          },
          params: {
            username: username,
            password: password,
            grant_type: "password",
          },
        })
        .then(({ data }) => {
          // console.log(data);
          this.$store.commit("authenticate", data.access_token);
          this.loadUser();
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: this.$t("message.usernameOrPasswordNotMatch"),
            type: "warning",
          });
        });
    },
    loadUser() {
      this.$http
        .get(`simple-user/current`)
        .then(({ data }) => {
          this.$store.commit("userData", data);
          this.$router.push("/");
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: this.$t("message.errorLoadingUser"),
            type: "warning",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.auth-container {
  height: 100%;
  width: 100%;
  background-color: #f1f1f1;

  .copyright {
    text-align: center;
    color: #b7b7b7;
  }
}
</style>
