<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.sectors") }}
      <div>
        <el-select
          v-if="$store.state.user.role === 'ADMIN'"
          size="mini"
          v-model="regionId"
          @change="regionChange"
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="district-select"
          size="mini"
          v-model="districtId"
          @change="loadSectors"
          filterable
        >
          <el-option
            v-for="district in districts"
            :label="district.name"
            :value="district.id"
            :key="district.id"
          >
          </el-option>
        </el-select>
        <linked-button type="primary" route="SectorAdd" icon="el-icon-plus">{{
          $t("message.add")
        }}</linked-button>
      </div>
    </div>
    <div class="table-wrap">
      <el-table
        border
        size="mini"
        :data="data"
        style="width: 100%"
        class="table"
      >
        <el-table-column type="index"> </el-table-column>
        <el-table-column
          width="150"
          :label="$t(`message.username`)"
          prop="username"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.sector`)" prop="sektorName">
        </el-table-column>
        <el-table-column :label="$t(`message.firstName`)" prop="firstName">
        </el-table-column>
        <el-table-column :label="$t(`message.lastName`)" prop="lastName">
        </el-table-column>
        <el-table-column
          :label="$t(`message.passportSerialNumber`)"
          prop="passportSerialNumber"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.phoneNumber`)" prop="phoneNumber">
        </el-table-column>
        <el-table-column width="200" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <LinkedButton
                route="SectorRate"
                type="solid"
                :params="{ id: scope.row.id }"
                icon="el-icon-star-on"
              />
              <LinkedButton
                route="UserChangePassword"
                :params="{ id: scope.row.id }"
                :query="{ username: scope.row.username }"
                icon="el-icon-key"
              />
              <LinkedButton
                route="SectorEdit"
                :params="{ id: scope.row.id }"
                type="primary"
                icon="el-icon-edit"
              />
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="deleteSector(scope.row.id)"
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-main> </el-main>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "SectorUserList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
      regions: [],
      districts: [],
      regionId: null,
      districtId: null,
    };
  },
  methods: {
    regionChange() {
      this.districtId = null;
      this.districts = [];
      this.$router
        .replace({
          query: {
            regionId: this.regionId,
          },
        })
        .catch(() => {});
      this.loadDistricts();
    },
    loadRegions() {
      this.loading = true;
      this.$http
        .get("region")
        .then(({ data }) => {
          this.regions = data;
          this.regionId = parseInt(this.$route.query.regionId || null, 10)
            ? parseInt(this.$route.query.regionId || null, 10)
            : this.regions[0].id;

          this.loadDistricts();
        })
        .catch(() => {
          this.loading = false;
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        });
    },
    loadDistricts() {
      this.loading = true;
      this.$http
        .get("district", {
          params: {
            id: this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
          this.districtId = parseInt(this.$route.query.districtId || null, 10)
            ? parseInt(this.$route.query.districtId || null, 10)
            : this.districts[0].id;

          this.loadSectors();
        })
        .catch(() => {
          this.loading = false;
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        });
    },
    loadSectors() {
      this.loading = true;
      this.$router.replace({
        query: {
          regionId: this.regionId,
          districtId: this.districtId,
        },
      });
      this.$http
        .get("sektor-user/byDistrict", {
          params: {
            districtId: this.districtId,
          },
        })
        .then(({ data }) => {
          this.data = this.sortList(data,'sektorName');
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteSector(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`sektor-user`, { params: { id } })
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadSectors();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    sortList(data, sortBy, sortedByASC = false) {
      if (sortedByASC) {
        data.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedByASC = false;
      } else {
        data.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedByASC = true;
      }
      return data;
    },
  },
  mounted() {
    if (this.$store.state.user.role === "REGION_ADMIN") {
      this.regionId = this.$store.state.user.regionId;
      this.loadDistricts();
    } else {
      this.loadRegions();
    }
  },
};
</script>

<style scoped lang="scss">
.district-select {
  margin-left: 10px;
  margin-right: 10px;
}
.table-wrap {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
