<template>
  <el-card v-loading.lock="loading">
    <div slot="header">
      {{ $t(`message.${title}`) }}
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="14">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  prop="question"
                  :label="this.$t('message.question')"
                >
                  <el-input
                    v-model="form.question"
                    :placeholder="this.$t('message.question')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  prop="description"
                  :label="this.$t('message.description')"
                >
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 7 }"
                    v-model="form.description"
                    :placeholder="this.$t('message.description')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="isForAll"
                  :label="this.$t('message.isForAll')"
                >
                  <el-checkbox v-model="form.isForAll">{{
                    $t("message.isForAll")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item
                  v-if="!form.isForAll"
                  prop="userType"
                  :label="this.$t('message.userType')"
                >
                  <el-select
                    v-model="form.userType"
                    filterable
                  >
                    <el-option
                      v-for="item in userRoles"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item>
                  <el-button type="primary" size="mini" @click="save">
                    {{ $t("message.save") }}
                  </el-button>
                  <BackButton>{{ $t("message.back") }}</BackButton>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "FaqForm",
  components: { BackButton },
  computed: {
    title() {
      return this.$route.params.id ? "editQuestion" : "addQuestion";
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        question: null,
        description: null,
        userType: null,
        isForAll: false,
      },
      rules: {
        question: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        description: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        userType: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
      },
      userRoles: [
        "SIMPLE_USER",
        "SEKTOR",
        "ADMIN",
        "REGION_ADMIN",
        "SEKTOR_ADMIN",
      ],
    };
  },
  methods: {
    getOneQuestion() {
      this.loading = true;
      this.$http
        .get(`faq/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.data.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let http;
          if (this.form.id) {
            http = this.$http.put("faq", this.form);
          } else {
            http = this.$http.post("faq", this.form);
          }

          http
            .then(() => {
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      this.getOneQuestion();
    }
  },
};
</script>

<style scoped></style>
