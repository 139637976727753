import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/Main";
import store from "@/store";
import Auth from "@/views/Auth";
import mainNav from "@/router/navigation";
// import ForgotPassword from "@/views/ForgotPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    name: "Main",
    path: "/dashboard",
    component: Main,
    beforeEnter: (to, from, next) => {
      if (store.state.accessToken) {
        if (to.path === "/dashboard") {
          const currentUser = store.state.user;
          const defaultPath = mainNav.find(
            (item) =>
              item.main &&
              (item.meta.authorize.includes(currentUser.role) ||
                !item.meta.authorize.length)
          );
          return next({ name: defaultPath.name });
        }
        next();
      } else {
        next("/auth");
      }
    },
    children: mainNav,
  },
  {
    name: "Auth",
    path: "/auth",
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.state.accessToken) {
        next({ path: "/" });
      } else {
        next();
      }
    },
  },
  // {
  //   name: "ForgotPassword",
  //   path: "/forgot",
  //   component: ForgotPassword,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.accessToken) {
  //       next({ path: "/" });
  //     } else {
  //       next();
  //     }
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
export default router;
