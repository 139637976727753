<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.faq") }}
      <div>
        <linked-button type="primary" route="FAQAdd" icon="el-icon-plus">{{
          $t("message.add")
        }}</linked-button>
      </div>
    </div>
    <div class="table-wrap">
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
      >
        <el-table-column type="index" :index="indexMethod"> </el-table-column>
        <el-table-column
          width="110"
          :label="$t(`message.user`)"
          prop="userType"
        >
        </el-table-column>
        <el-table-column
          width="180"
          :label="$t(`message.question`)"
          prop="question"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.description`)" prop="description">
        </el-table-column>
        <el-table-column width="160" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <LinkedButton
                route="FAQEdit"
                :params="{ id: scope.row.id }"
                type="primary"
                icon="el-icon-edit"
              />
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="deleteFaq(scope.row.id)"
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElements"
            :page-size="data.size"
            :page-count="data.totalPages"
            :hide-on-single-page="true"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElements > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElements}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-main> </el-main>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "FaqList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
      userType: null,
      page: null,
    };
  },
  methods: {
    loadFaqList() {
      this.loading = false;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("faq/admin", {
          params: {
            userType: this.userType,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteFaq(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`faq/${id}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadFaqList();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$router.replace({ query: { page: val } }).catch(() => {});
      this.loadFaqList();
    },
    indexMethod(index) {
      if (this.data.totalPages > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.loadFaqList();
  },
};
</script>

<style scoped lang="scss">
.table-wrap {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
</style>
