<template>
  <div id="main" style="width: 100%; height: 100%; min-height: 600px"></div>
</template>

<script>
import echarts from "echarts";

export default {
  name: "Chart",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    initChart(data) {
      // console.log("init");
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      this.chart = echarts.init(document.getElementById("main"));
      const option = {
        title: {
          text: this.title,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        dataset: {
          dimensions: ["name", "answered", "unanswered"],
          source: data,
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#57617B",
            },
          },
          axisLabel: {
            formatter: function (value, index) {
              let star = "";

              for (let i = 0; i < data[index].avgRate; i++) {
                star = star + "{star| } ";
              }

              return value + ": " + data[index].allCount + " \n" + star;
            },
            rich: {
              value: {
                fontSize: 25,
                padding: 5,
              },
              star: {
                height: 20,
                align: "center",
                backgroundColor: {
                  image: require("@/assets/images/star.png"),
                },
              },
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          // {
          //   name: this.$t("message.allCount"),
          //   type: "bar",
          //   barGap: 0,
          //   showBackground: true,
          //   backgroundStyle: {
          //     color: "rgba(180, 180, 180, 0.2)",
          //   },
          //   stack: 'total',
          //   label: {
          //     show: true
          //   },
          //   emphasis: {
          //     focus: 'series'
          //   },
          // },
          {
            name: this.$t("message.answered"),
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
          },
          {
            name: this.$t("message.unAnswered"),
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
          },
        ],
      };

      this.chart.setOption(option);
      this.chart.on("click", "series", (params) => {
        this.onClickChart(params.data);
      });
    },

    onClickChart(data) {
      this.$emit("onSelectItem", data);
    },
  },
  mounted() {
    // this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
};
</script>

<style scoped></style>
