<template>
  <el-card id="doc-form" v-loading.lock="loading">
    <div class="header" slot="header">
      <h1 class="header-title">{{ $t("message.statistics") }}</h1>
      <div>
        <el-select
          class="header-item"
          size="mini"
          v-model="regionId"
          @change="regionChange"
          clearable
          :loading="regionLoading"
          :loading-text="$t('message.loading')"
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="district-select header-item"
          size="mini"
          v-model="districtId"
          @change="districtChange"
          clearable
          :loading="districtLoading"
          :loading-text="$t('message.loading')"
          filterable
        >
          <el-option
            v-for="district in districts"
            :label="district.name"
            :value="district.id"
            :key="district.id"
          >
          </el-option>
        </el-select>
        <el-select
            class="quarter-select header-item"
            size="mini"
            v-model="mahallaId"
            @change="quarterChange"
            clearable
            :loading="quarterLoading"
            :loading-text="$t('message.loading')"
            filterable
        >
          <el-option
              v-for="quarter in quarters"
              :label="quarter.name"
              :value="quarter.id"
              :key="quarter.id"
          >
          </el-option>
        </el-select>
        <el-select
            class="sector-select header-item"
            size="mini"
            v-model="sektorId"
            @change="getStatistics"
            clearable
            :loading="sectorLoading"
            :loading-text="$t('message.loading')"
            filterable
        >
          <el-option
              v-for="sector in sectors"
              :label="sector.sektorName"
              :value="sector.id"
              :key="sector.id"
          >
          </el-option>
        </el-select>
        <el-date-picker
          class="header-item"
          @change="getStatistics"
          :clearable="false"
          size="mini"
          v-model="range"
          type="datetimerange"
          align="right"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="timestamp"
          format="dd.MM.yyyy HH:mm"
          :default-time="['00:00:00', '23:59:00']"
        >
        </el-date-picker>
        <el-button
          class="header-item"
          size="mini"
          @click="downloadXls"
          type="primary"
          icon="el-icon-document"
          >{{ $t("message.download") }}</el-button
        >
      </div>
    </div>
    <el-main class="main">
      <Chart ref="chart" :data="data" @onSelectItem="selectChartItem" />
    </el-main>
  </el-card>
</template>

<script>
import Chart from "@/components/Chart";
import axios from "axios";
export default {
  name: "Monitoring",
  components: { Chart },
  data() {
    return {
      loading: false,
      data: [],
      range: [
        this.$moment()
          .clone()
          .startOf("year")
          .hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
        this.$moment().hour(23).minute(59).second(59).valueOf(),
      ],
      regions: [],
      districts: [],
      quarters: [],
      sectors: [],
      regionId: null,
      districtId: null,
      mahallaId: null,
      sektorId: null,
      regionLoading: false,
      districtLoading: false,
      quarterLoading: false,
      sectorLoading: false,
    };
  },
  methods: {
    selectChartItem(data) {
      console.log(data);
      // console.log(this.$route.name);
      // switch (this.$route.name) {
      //   case "RegionsMonitoring":
      //     this.$router.push({
      //       name: "DistrictsMonitoring",
      //       params: { id: data.id },
      //     });
      //     break;
      //   case "DistrictsMonitoring":
      //     this.$router.push({
      //       name: "SectorMonitoring",
      //       params: { id: data.id },
      //     });
      //     break;
      // }
    },
    regionChange() {
      this.districtId = null;
      this.mahallaId = null;
      this.sektorId = null;
      this.districts = [];
      this.quarters = [];
      this.sectors = [];

      if (this.regionId) {
        this.loadDistricts();
      }
      this.getStatistics();
    },
    districtChange() {
      this.mahallaId = null;
      this.sektorId = null;
      this.quarters = [];
      this.sectors = [];

      if (this.districtId) {
        this.loadQuarters();
      }
      this.getStatistics();
    },
    quarterChange() {
      this.sektorId = null;
      this.sectors = [];

      if (this.mahallaId) {
        this.loadSectors();
      }
      this.getStatistics();
    },
    loadRegions() {
      this.regionLoading = true;
      this.$http
        .get("region")
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.regionLoading = false;
        });
    },
    loadDistricts() {
      this.districtLoading = true;
      this.$http
        .get("district", {
          params: {
            id: this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.districtLoading = false;
        });
    },
    loadQuarters() {
      this.quarterLoading = true;
      this.$http
        .get("mahalla", {
          params: {
            id: this.districtId,
          },
        })
        .then(({ data }) => {
          this.quarters = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.quarterLoading = false;
        });
    },
    loadSectors() {
      this.sectorLoading = true;
      this.$http
        .get("sektor-user/byDistrict", {
          params: {
            districtId: this.districtId,
          },
        })
        .then(({ data }) => {
          this.sectors = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.sectorLoading = false;
        });
    },
    getStatistics() {
      // console.log('regionId: ', this.regionId);
      // console.log('districtId: ', this.districtId);
      // console.log('mahallaId: ', this.mahallaId);
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASE_API_URL + "admin/statistics", {
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            regionId: this.mahallaId || this.sektorId ? null : this.regionId,
            districtId: this.mahallaId || this.sektorId ? null : this.districtId,
            mahallaId: this.sektorId ? null : this.mahallaId,
            sektorId: this.sektorId,
          },
        })
        .then(({ data }) => {
          this.data = data;
          this.$refs["chart"].initChart(data);
        })
        .catch(({ response }) => {
          this.$alert(
            response.data.message
              ? response.data.message
              : "Error loading data!",
            "Error",
            {
              confirmButtonText: "Ok",
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadXls() {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}admin/statistics-excel`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            regionId: this.regionId,
          },
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
      var blob = new Blob([res.data], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        `${
          this.regionId
            ? this.regions.find((item) => item.id === this.regionId).name
            : ""
        }-${this.$moment(this.range[0]).format("DD/MM/YYYY")}-${this.$moment(
          this.range[1]
        ).format("DD/MM/YYYY")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
  },
  mounted() {
    // switch (this.$route.name) {
    //   case "DistrictsMonitoring":
    //     this.regionId = this.$route.params.id;
    //     break;
    //   case "SectorMonitoring":
    //     this.districtId = this.$route.params.id;
    //     break;
    // }
    this.loadRegions();
    this.getStatistics();
  },
};
</script>

<style scoped lang="scss">
.header-item {
  margin-left: 10px;
}
.main {
  height: calc(100vh - 200px);
  padding: 0;
}
</style>
