<template>
  <el-card class="appeal-card" v-loading.lock="loading">
    <el-main class="main">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-card shadow="never" class="box-card">
            <div slot="header">
              <BackButton
                style="float: left; padding: 3px 0"
                type="text"
                icon="el-icon-back"
                >{{ $t("message.back") }}</BackButton
              >
              <span style="margin-left: 20px">{{ $t("message.appeal") }}</span>
            </div>
            <el-main class="main" v-if="appeal">
              <div class="field">
                <div class="field-name">{{ $t("message.applicant") }}:</div>
                <div class="field-value">
                  <linked-button
                    type="text"
                    route="AppealList"
                    :query="{ applicant: appeal.creatorId }"
                    >{{ appeal.applicantDto.fullName }}</linked-button
                  >
                </div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.birthDate") }}:</div>
                <div class="field-value">
                  {{ appeal.applicantDto.birthDate }}
                </div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.email") }}:</div>
                <div class="field-value">{{ appeal.applicantDto.email }}</div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.phoneNumber") }}:</div>
                <div class="field-value">
                  {{ appeal.applicantDto.phoneNumber }}
                </div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.location") }}:</div>
                <div class="field-value">
                  {{ appeal.applicantDto.location }}
                </div>
              </div>
              <div class="field">
                <div class="field-name">
                  {{ $t("message.appealTransparency") }}:
                </div>
                <div class="field-value">
                  {{ appeal.appealTransparencyName }}
                </div>
              </div>
              <!-- <div class="field">
                <div class="field-name">{{ $t("message.appealType") }}:</div>
                <div class="field-value">{{ appeal.appealTypeName }}</div>
              </div> -->
              <div class="field">
                <div class="field-name">{{ $t("message.content") }}:</div>
                <div class="field-value" v-if="appeal.content">
                  {{ appeal.content }}
                </div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.status") }}:</div>
                <div class="field-value" :class="appeal.status.toLowerCase()">
                  {{ $t(`message.${appeal.status.toLowerCase()}`) }}
                </div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.createdDate") }}:</div>
                <div class="field-value">
                  {{ $moment(appeal.createdDate).format("DD.MM.YYYY") }}
                </div>
              </div>
              <div class="field">
                <div class="field-name">{{ $t("message.files") }}:</div>
                <div v-if="!appeal.filesDto.length" class="field-value">
                  {{ $t("message.notAvailable") }}
                </div>
              </div>
              <div
                class="file-list"
                v-for="file in appeal.filesDto"
                :key="file.id"
                @click="() => getFile(file.id, file.fileName)"
              >
                <i class="el-icon-paperclip"></i> {{ file.fileName }}
              </div>

              <ul class="file-list audio-wrapper">
                <li
                  v-for="(audioFile, index) in appeal.audiosDto"
                  :key="audioFile.id"
                >
                  <p>Audio {{ index + 1 }}</p>
                  <audio :src="audioPath" controls>
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                </li>
              </ul>

              <el-dialog
                destroy-on-close
                title=""
                :visible.sync="dialogVisible"
              >
                <div class="image-container">
                  <!--          <img id="image" class="image" />-->
                  <!--          <audio ref="audio" id="audio" controls class="audio"></audio>-->
                  <div id="content"></div>
                </div>
              </el-dialog>
              <el-button
                style="margin-top: 30px"
                type="primary"
                icon="el-icon-download"
                size="mini"
                @click="getPdf"
                >{{ $t("message.download") }} (pdf)</el-button
              >
            </el-main>
          </el-card>
        </el-col>
        <el-col style="height: 100%" :span="12">
          <el-card shadow="never" class="box-card">
            <div slot="header">
              <span>{{ $t("message.responseToAppeal") }}</span>
            </div>
            <el-main v-if="appeal">
              <el-form
                v-if="
                  appeal.status !== 'ANSWERED' &&
                  appeal.status !== 'REJECTED' &&
                  $store.state.user.role === 'SEKTOR'
                "
                hide-required-asterisk
                size="small"
                class="form"
                :model="responseForm"
                label-position="top"
                ref="form"
                :rules="rules"
              >
                <el-form-item prop="content" :label="$t('message.content')">
                  <el-input
                    type="textarea"
                    v-model="responseForm.content"
                    :placeholder="$t('message.content')"
                    :rows="15"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item :label="$t('message.files')">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :headers="{
                      Authorization: `Bearer ${$store.state.accessToken}`,
                    }"
                    :before-remove="handleRemove"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :file-list="fileList"
                  >
                    <el-button size="small" type="primary">{{
                      $t("message.uploadFile")
                    }}</el-button>
                  </el-upload>
                </el-form-item>
                <el-form-item>
                  <BackButton icon="el-icon-back">{{
                    $t("message.back")
                  }}</BackButton>

                  <el-button type="primary" size="mini" @click="save(false)">
                    {{ $t("message.save") }}
                  </el-button>
                  <el-button type="danger" size="mini" @click="reject">
                    {{ $t("message.reject") }}
                  </el-button>
                  <el-button
                    :disabled="appeal.status === 'EXTENDED'"
                    icon="el-icon-timer"
                    type="success"
                    size="mini"
                    @click="extend"
                  >
                    {{ $t("message.extend") }}
                  </el-button>
                </el-form-item>
              </el-form>

              <div v-else-if="appeal.appealResponseDto">
                <div class="field">
                  <div class="field-name">{{ $t("message.content") }}:</div>
                  <div class="field-value">
                    {{ appeal.appealResponseDto.content }}
                  </div>
                </div>
                <div class="field">
                  <div class="field-name">{{ $t("message.createdDate") }}:</div>
                  <div class="field-value">
                    {{
                      $moment(appeal.appealResponseDto.createdDate).format(
                        "DD.MM.YYYY"
                      )
                    }}
                  </div>
                </div>
                <div class="field">
                  <div class="field-name">{{ $t("message.rate") }}:</div>
                  <div class="field-value">
                    <el-rate
                      v-if="appeal.appealResponseDto.status === 'RATED'"
                      :value="appeal.appealResponseDto.rate"
                    ></el-rate>
                    <span v-else>{{ $t("message.unrated") }}</span>
                  </div>
                </div>
                <div class="field">
                  <div class="field-name">{{ $t("message.files") }}:</div>
                  <div
                    v-if="!appeal.appealResponseDto.filesDto.length"
                    class="field-value"
                  >
                    {{ $t("message.notAvailable") }}
                  </div>
                </div>
                <div
                  class="file-list"
                  v-for="file in appeal.appealResponseDto.filesDto"
                  :key="file.id"
                  @click="() => getFile(file.id, file.fileName)"
                >
                  <i class="el-icon-paperclip"></i> {{ file.fileName }}
                </div>
              </div>
              <div v-else class="response-not-given">
                <span>Murojaatga javob berilmagan</span>
              </div>
            </el-main>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
import { createPdf } from "@/utils/pdfMaker";
import axios from "axios";
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "ResponseAppeal",
  components: { LinkedButton, BackButton },
  computed: {
    uploadUrl() {
      return process.env.VUE_APP_BASE_API_URL + "file/upload";
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      appeal: null,
      appealId: null,
      selectedImage: null,
      fileList: [],
      responseForm: {
        id: null,
        responseToId: null,
        content: null,
        filesId: [],
        status: "UNRATED",
      },
      audioFiles: [
        { src: "path/to/audio1.mp3", title: "Audio 1" },
        { src: "path/to/audio2.mp3", title: "Audio 2" },
        { src: "path/to/audio3.mp3", title: "Audio 3" },
      ],
      audioPath: "",
      rules: {
        content: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
      },
    };
  },

  methods: {
    getPdf() {
      createPdf(this.appeal);
    },

    async getAudio(id) {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}file/audio/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.audioPath = res.config.url;
        })
        .catch(({ response }) => {
          this.$alert(
            response.data.message
              ? response.data.message
              : "Error loading data!",
            "Error",
            {
              confirmButtonText: "Ok",
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });

      if (res.headers["content-type"].includes("audio")) {
        this.dialogVisible = true;

        this.$nextTick(() => {
          const content = document.getElementById("content");

          const audio = document.createElement("audio");
          audio.src = window.URL.createObjectURL(res.data);
          audio.type = res.headers["content-type"];
          audio.controls = true;

          content.appendChild(audio);
          audio.play();
        });
      }
    },

    async getFile(id, filename) {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}file/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          responseType: "blob",
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.data.message
              ? response.data.message
              : "Error loading data!",
            "Error",
            {
              confirmButtonText: "Ok",
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });

      if (res.headers["content-type"].includes("image")) {
        this.dialogVisible = true;

        this.$nextTick(() => {
          const content = document.getElementById("content");
          const img = document.createElement("img");
          img.src = URL.createObjectURL(res.data);
          img.className = "image";
          img.style.maxHeight = "500px";
          content.appendChild(img);
        });
      } else if (res.headers["content-type"].includes("audio")) {
        this.dialogVisible = true;

        this.$nextTick(() => {
          const content = document.getElementById("content");

          const audio = document.createElement("audio");
          audio.src = window.URL.createObjectURL(res.data);
          audio.type = res.headers["content-type"];
          audio.controls = true;

          content.appendChild(audio);
          audio.play();
        });
      } else if (res.headers["content-type"].includes("video")) {
        this.dialogVisible = true;

        this.$nextTick(() => {
          const content = document.getElementById("content");

          const video = document.createElement("video");
          video.src = window.URL.createObjectURL(res.data);
          video.type = res.headers["content-type"];
          video.style.maxHeight = "600px";
          video.controls = true;
          content.appendChild(video);
          video.play();
        });
      } else {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    },
    handleRemove(file) {
      const elementToDelete = file.response;
      // console.log(elementToDelete);
      this.responseForm.filesId = this.responseForm.filesId.filter(
        (item) => elementToDelete !== item
      );
    },
    uploadSuccess(response) {
      this.$set(
        this.responseForm.filesId,
        this.responseForm.filesId.length,
        response
      );
    },
    uploadError() {
      this.$notify.error({
        title: this.$t("message.error"),
        message: this.$t("message.uploadError"),
      });
    },
    reject() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm(
            this.$t("message.sureToReject"),
            this.$t("message.reject"),
            {
              confirmButtonText: this.$t("message.yes"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning",
            }
          ).then(() => {
            this.save(true);
          });
        }
      });
    },
    save(reject = false) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const appealStatus = reject ? "REJECTED" : null;
          this.$http
            .post("appeal-response", this.responseForm, {
              params: {
                appealStatus,
              },
            })
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    async getAppeal() {
      this.loading = true;
      await this.$http
        .get(`card-102/${this.appealId}`)
        .then(({ data }) => {
          this.appeal = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    extend() {
      this.$confirm(
        this.$t("message.sureToExtend"),
        this.$t("message.extend"),
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.cancel"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .put(`card102/extend/${this.appealId}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullySaved"),
              type: "success",
            });
            this.$router.go(-1);
          })
          .catch(() => {
            this.$alert(
              this.$t("message.errorLoadingData"),
              this.$t("message.error"),
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
  created() {
    // console.log("created");
    // this.responseForm.responseToId = this.$route.params.id;
    // this.getAppeal();
    // this.getAppeal();
    // console.log(
    //   this.getAppeal().then((res) => res),
    //   "this.getAppeal()"
    // );
  },
  async mounted() {
    // console.log("mounted");
    this.appealId = this.$route.params.id;
    this.responseForm.responseToId = this.$route.params.id;
    await this.getAppeal();
    this.getAudio(this.appeal.audiosDto[0].id);
  },
};
</script>
<style lang="scss">
.appeal-card {
  .el-card__body {
    padding: 0;
  }
}
</style>

<style scoped lang="scss">
.box-card {
  height: calc(100vh - 140px);
  overflow-y: auto;
  .response-not-given {
    color: #a7aebd;
    text-align: center;
  }
}
.main {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.field {
  margin-top: 15px;
  display: flex;
  &-name {
    color: #a0a0a5;
    text-align: left;
    flex-basis: 40%;
  }
  &-value {
    text-align: left;
    flex-basis: 60%;
  }
  .answered {
    background-color: #4ce2a7;
    padding: 3px;
  }
  .rejected {
    background-color: #ff1232;
    padding: 3px;
  }
  .opened {
    background-color: #a7aebd;
    padding: 3px;
  }
}
.photos {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &-item {
    flex-basis: 32%;
    margin-bottom: 15px;
    cursor: zoom-in;
  }
}
.file-list {
  margin-top: 10px;
  &:hover {
    color: #2a3098;
    cursor: pointer;
  }
}
.image-container {
  display: flex;
  justify-content: space-around;
  img {
    align-items: center;
    max-height: 500px;
  }
  .image {
    align-items: center;
    max-height: 500px;
  }
}
.audio-wrapper {
  margin-top: 25px;
}
</style>
