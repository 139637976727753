import Vue from "vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/ru-RU";
import App from "./App.vue";
import "./assets/themes/core.scss";
import router from "./router";
import store from "./store";
import moment from "moment";
import http from "./http";
import i18n from "@/i18n/i18n";
import VInputmask from 'v-inputmask';

Vue.use(VInputmask);

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.prototype.$moment = moment;
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
