<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.quarters") }}
      <div style="display: flex">
        <div v-if="$store.state.user.role !== 'SEKTOR'">
          <el-select
            size="mini"
            v-model="regionId"
            @change="changeFilter('region')"
            clearable
            filterable
          >
            <el-option
              v-for="region in regions"
              :label="region.name"
              :value="region.id"
              :key="region.id"
            >
            </el-option>
          </el-select>
          <el-select
            class="district-select"
            size="mini"
            v-model="districtId"
            @change="changeFilter('district')"
            clearable
            filterable
          >
            <el-option
              v-for="district in districts"
              :label="district.name"
              :value="district.id"
              :key="district.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          class="header-item"
          size="mini"
          @click="downloadXls"
          type="primary"
          :disabled="!districtId"
          icon="el-icon-document"
          >{{ $t("message.download") }}</el-button
        >
        <linked-button type="success" route="QuarterAdd" icon="el-icon-plus">{{
          $t("message.add")
        }}</linked-button>
      </div>
    </div>
    <div class="table-wrap">
      <el-table
        @row-dblclick="rowClick"
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        height="calc(100vh - 232px)"
        class="table"
      >
        <el-table-column :index="indexMethod" type="index"> </el-table-column>
        <el-table-column
          sortable
          width="200"
          :label="$t(`message.name`)"
          prop="name"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.sektorId`)" prop="sektorId">
        </el-table-column>
        <el-table-column :label="$t(`message.familyCount`)" prop="familyCount">
        </el-table-column>

        <el-table-column
          :label="$t(`message.apartmentCount`)"
          prop="apartmentCount"
        >
        </el-table-column>
        <el-table-column :label="$t(`message.population`)" prop="population">
        </el-table-column>
        <el-table-column :label="$t(`message.maleCount`)" prop="maleCount">
        </el-table-column>
        <el-table-column :label="$t(`message.femaleCount`)" prop="femaleCount">
        </el-table-column>
        <el-table-column
          width="250"
          :label="$t(`message.mahallaMayor`)"
          prop="mahallaMayor"
        >
          <template slot-scope="scope">
            {{
              (scope.row.mayorFirstName || "") +
              " " +
              (scope.row.mayorLastName || "") +
              " " +
              (scope.row.mayorMiddleName || "")
            }}
          </template>
        </el-table-column>
        <el-table-column width="120" align="center">
          <template slot-scope="scope">
            <el-button
              slot="reference"
              type="danger"
              @click="deleteQuarter(scope.row.id)"
              icon="el-icon-delete"
              size="mini"
            ></el-button>
            <LinkedButton
              route="QuarterEdit"
              :params="{ id: scope.row.id }"
              :query="{ name: scope.row.name, districtId }"
              type="primary"
              icon="el-icon-edit"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElements"
            :page-size="data.size"
            :page-count="data.totalPages"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
            hide-on-single-page
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElements > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElements}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
import axios from "axios";
export default {
  name: "QuartersList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      regions: [],
      districts: [],
      regionId: null,
      districtId: null,
      data: {},
      page: null,
    };
  },
  methods: {
    async downloadXls() {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}mahalla/excel`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          params: { id: this.districtId },
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
      var blob = new Blob([res.data], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "mahallalar.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    changeFilter(type) {
      switch (type) {
        case "region":
          this.districts = [];
          this.districtId = null;
          this.$router.replace({ query: { regionId: this.regionId } });
          this.loadDistricts();
          break;
        case "district":
          this.$router.replace({
            query: { regionId: this.regionId, districtId: this.districtId },
          });
      }
      this.loadQuarters();
    },
    loadRegions() {
      this.loading = true;
      this.$http
        .get("region")
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadDistricts() {
      if (this.regionId) {
        this.loading = true;
        this.$http
          .get("district", {
            params: {
              id: this.regionId,
            },
          })
          .then(({ data }) => {
            this.districts = data;
          })
          .catch(() => {
            this.$alert(
              this.$t("message.errorLoadingData"),
              this.$t("message.error"),
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    loadQuarters() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("mahalla/page", {
          params: {
            regionId: this.regionId,
            districtId: this.districtId,
            page: this.page,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteQuarter(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`mahalla/${id}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadQuarters();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    rowClick(row) {
      this.$router.push({
        name: "QuarterEdit",
        params: { id: row.id },
        query: { name: row.name, districtId: this.districtId },
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$router
        .replace({
          query: {
            regionId: this.regionId,
            districtId: this.districtId,
            page: val,
          },
        })
        .catch(() => {});
      this.loadQuarters();
    },
    indexMethod(index) {
      if (this.data.totalPages > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
  },
  mounted() {
    if (this.$store.state.user.role === "SEKTOR") {
      this.districtId = this.$store.state.user.districtId;
      this.loadQuarters();
    } else {
      this.loadRegions();
      if (this.$route.query.regionId) {
        this.regionId = Number.parseInt(this.$route.query.regionId || "", 10);
        this.loadDistricts();
      }
      if (this.$route.query.districtId) {
        this.districtId = Number.parseInt(
          this.$route.query.districtId || "",
          10
        );
      }
      this.loadQuarters();
    }
  },
};
</script>

<style scoped lang="scss">
.district-select {
  margin-left: 20px;
}
.header-item {
  margin-left: 10px;
}
.table-wrap {
  max-height: calc(100vh - 195px);
  overflow-y: auto;
}
.pagination {
  margin-top: 5px;
}
.pagination-total {
  margin-top: 10px;
}
</style>
