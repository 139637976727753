<template>
  <el-card v-loading.lock="loading" :body-style="{ padding: '2px' }">
    <div slot="header" class="header">
      {{ $t("message.users") }}
      <div>
        <el-select
          v-if="$store.state.user.role === 'ADMIN'"
          class="header-item"
          size="mini"
          :loading="regionLoading"
          :loading-text="$t('message.loading')"
          v-model="regionId"
          :placeholder="$t('message.region')"
          @change="changeFilter('region')"
          clearable
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="
            $store.state.user.role === 'REGION_ADMIN' ||
            $store.state.user.role === 'ADMIN'
          "
          class="header-item"
          size="mini"
          :loading="districtLoading"
          :loading-text="$t('message.loading')"
          v-model="districtId"
          :placeholder="$t('message.district')"
          @change="changeFilter('district')"
          clearable
          filterable
        >
          <el-option
            v-for="district in districts"
            :label="district.name"
            :value="district.id"
            :key="district.id"
          >
          </el-option>
        </el-select>
        <el-date-picker
          class="header-item"
          @change="getUsers"
          :clearable="false"
          size="mini"
          v-model="range"
          type="datetimerange"
          align="right"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="timestamp"
          format="dd.MM.yyyy HH:mm"
          :default-time="['00:00:00', '23:59:00']"
        >
        </el-date-picker>
        <el-button
          class="header-item"
          size="mini"
          @click="downloadXls"
          type="primary"
          icon="el-icon-document"
          >{{ $t("message.download") }}</el-button
        >
      </div>
    </div>
    <div class="main">
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
        height="calc(100vh - 210px)"
      >
        <el-table-column type="index" :index="indexMethod"> </el-table-column>
        <el-table-column :label="$t(`message.firstName`)" prop="firstName">
          <template slot-scope="scope">
            {{ scope.row.firstname + " " + scope.row.lastname }}
          </template>
        </el-table-column>
        <el-table-column :label="$t(`message.phoneNumber`)" prop="phoneNumber">
        </el-table-column>
        <el-table-column :label="$t(`message.region`)" prop="regionName">
        </el-table-column>
        <el-table-column :label="$t(`message.district`)" prop="districtName">
        </el-table-column>
        <el-table-column :label="$t(`message.mahalla`)" prop="mahallaName">
        </el-table-column>
        <el-table-column :label="$t(`message.createdDate`)" prop="createdDate">
          <template slot-scope="scope">
            {{ $moment(scope.row.createdDate).format("DD.MM.YYYY") }}
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElement"
            :page-size="data.size"
            :page-count="data.totalPage"
            :hide-on-single-page="true"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElement > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElement}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import axios from "axios";

export default {
  name: "RegisteredUsers",
  data() {
    return {
      loading: false,
      data: [],
      range: [
        this.$moment()
          .clone()
          .startOf("month")
          .hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
        this.$moment().hour(23).minute(59).second(59).valueOf(),
      ],
      page: null,
      regions: [],
      regionId: null,
      regionLoading: false,
      districts: [],
      districtId: null,
      districtLoading: false,
    };
  },
  methods: {
    async downloadXls() {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}admin/users-excel`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            regionId: this.regionId,
            districtId: this.districtId,
          },
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
      var blob = new Blob([res.data], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        this.$moment(this.range[0]).format("DD.MM.YYYY") +
          "-" +
          this.$moment(this.range[1]).format("DD.MM.YYYY") +
          ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
    },

    changeFilter(type) {
      switch (type) {
        case "region":
          this.districts = [];
          this.districtId = null;
          this.$router.replace({ query: { regionId: this.regionId } });
          this.getDistricts();
          break;
        case "district":
          this.$router.replace({
            query: { regionId: this.regionId, districtId: this.districtId },
          });
      }
      this.getUsers();
    },
    getRegions() {
      this.regionLoading = true;
      this.$http
        .get(`region`)
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {})
        .finally(() => {
          this.regionLoading = false;
        });
    },
    getDistricts() {
      this.districtLoading = true;
      this.$http
        .get(`district`, {
          params: {
            id: this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {})
        .finally(() => {
          this.districtLoading = false;
        });
    },
    getUsers() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("admin/users", {
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            page: this.page,
            regionId: this.regionId,
            districtId: this.districtId,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$router.replace({ query: { page: val } }).catch(() => {});
      this.getUsers();
    },
    indexMethod(index) {
      if (this.data.totallPage > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
  },
  mounted() {
    this.getRegions();
    if (this.$route.query.regionId) {
      this.regionId = Number.parseInt(this.$route.query.regionId || "", 10);
    }
    if (this.$route.query.districtId) {
      this.districtId = Number.parseInt(this.$route.query.districtId || "", 10);
      this.getDistricts();
    }
    this.getUsers();
  },
};
</script>

<style scoped lang="scss">
.header {
  justify-content: space-between;
  .header-item {
    margin-left: 10px;
  }
}
.main {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}
.table {
}
.pagination {
  margin-top: 10px;
}
.pagination-total {
  margin-top: 15px;
}
</style>
