<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      <back-button icon="el-icon-back">{{ $t("message.back") }}</back-button>
      {{ $t("message.sectorRates") }}
      <el-select
        class="header-item"
        size="mini"
        v-model="rate"
        @change="getSectorRates"
        clearable
        :placeholder="$t('message.rate')"
        filterable
      >
        <el-option
          v-for="rate in rates"
          :label="rate + ' ' + $t(`message.rate`)"
          :value="rate"
          :key="rate"
        >
        </el-option>
      </el-select>
    </div>
    <el-main>
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
        height="calc(100vh - 230px)"
      >
        <el-table-column align="center" type="index" :index="indexMethod">
        </el-table-column>
        <el-table-column :label="$t(`message.firstName`)" prop="firstName">
        </el-table-column>
        <el-table-column :label="$t(`message.lastName`)" prop="lastName">
        </el-table-column>
        <el-table-column :label="$t(`message.phoneNumber`)" prop="phoneNumber">
        </el-table-column>

        <el-table-column width="110" :label="$t(`message.rate`)" prop="rate">
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElements"
            :page-size="data.size"
            :page-count="data.totalPages"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
            hide-on-single-page
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElements > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElements}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";

export default {
  name: "SectorRate",
  components: { BackButton },
  data() {
    return {
      loading: false,
      inspectorId: null,
      rate: null,
      rates: [5, 4, 3, 2, 1],
      data: {},
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.$router.replace({ query: { page: val } }).catch(() => {});
      this.getInspectors();
    },
    indexMethod(index) {
      if (this.data.totalPage > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
    getSectorRates() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("sektor-user/rate", {
          params: {
            id: this.inspectorId,
            rate: this.rate,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.inspectorId = this.$route.params.id;
    this.getSectorRates();
  },
};
</script>

<style scoped></style>
