import QuartersList from "@/views/quarters/QuartersList";
import QuarterForm from "@/views/quarters/QuarterForm";
import LinkList from "@/views/Links/LinkList";
import LinkForm from "@/views/Links/LinkForm";
import SectorUserList from "@/views/users/SectorUserList";
import CommonAppealList from "@/views/common-appeal/CommonAppealList";
import CommonAppealForm from "@/views/common-appeal/CommonAppealForm";
import SectorUserForm from "@/views/users/SectorUserForm";
import AppealList from "@/views/appeals/AppealList";
import ResponseAppeal from "@/views/appeals/ResponseAppeal";
import Response102 from "@/views/102/102Response";
import UserOwnChangePasswordForm from "@/views/changePassword/UserOwnChangePasswordForm";
import Monitoring from "@/views/statistics/Monitoring";
import RegionList from "@/views/Regions/RegionList";
import RegionForm from "@/views/Regions/RegionForm";
import UserChangePasswordForm from "@/views/users/UserChangePasswordForm";
import HelpList from "@/views/help/HelpList";
import Help from "@/views/help/Help";
import FaqList from "@/views/faq/FaqList";
import FaqForm from "@/views/faq/FaqForm";
import FaqForUsers from "@/views/faq/FaqForUsers";
import AppealListForAdmin from "@/views/appeals/AppealListForAdmin";
import AppealListForAdmin102 from "@/views/102/102ListForAdmin";
import RegisteredUsers from "@/views/users/RegisteredUsers";
import InspectorList from "@/views/inspector/InspectorList";
import InspectorForm from "@/views/inspector/InspectorForm";
import InspectorRate from "@/views/inspector/InspectorRate";
import SectorRate from "@/views/users/SectorRate";
import SectorAdminList from "@/views/sectorAdmin/SectorAdminList";
import SectorAdminForm from "@/views/sectorAdmin/SectorAdminForm";

const mainNav = [
  {
    main: true,
    isSubmenu: false,
    title: "sectors",
    icon: "el-icon-user",
    name: "SectorList",
    path: "sector-users",
    component: SectorUserList,
    meta: { authorize: ["ADMIN", "REGION_ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "sectorAdmins",
    icon: "el-icon-user",
    name: "SectorAdminList",
    path: "sector-admins",
    component: SectorAdminList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "SectorAdminAdd",
    path: "sector-admin/add",
    component: SectorAdminForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "SectorAdminEdit",
    path: "sector-admin/edit/:id",
    component: SectorAdminForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "regionAdmins",
    icon: "el-icon-user",
    name: "RegionList",
    path: "regions",
    component: RegionList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "RegionAdd",
    path: "region/add",
    component: RegionForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "RegionEdit",
    path: "region/edit/:id",
    component: RegionForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "users",
    icon: "el-icon-user-solid",
    name: "RegisteredUsers",
    path: "users",
    component: RegisteredUsers,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "inspectors",
    icon: "el-icon-user-solid",
    name: "InspectorList",
    path: "inspector/list",
    component: InspectorList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "InspectorAdd",
    path: "inspector/add",
    component: InspectorForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "InspectorEdit",
    path: "inspector/edit/:id",
    component: InspectorForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "InspectorRate",
    path: "inspector/rate/:id",
    component: InspectorRate,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "statistics",
    icon: "el-icon-data-analysis",
    name: "RegionsMonitoring",
    path: "monitoring/regions",
    component: Monitoring,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "DistrictsMonitoring",
    path: "monitoring/districts/:id",
    component: Monitoring,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "SectorMonitoring",
    path: "monitoring/sector/:id",
    component: Monitoring,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "SectorAdd",
    path: "sector-users/add",
    component: SectorUserForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "SectorRate",
    path: "sector-users/rate/:id",
    component: SectorRate,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "SectorEdit",
    path: "sector-users/edit/:id",
    component: SectorUserForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "UserChangePassword",
    path: "sector-users/change-pwd/:id",
    component: UserChangePasswordForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "quarters",
    icon: "el-icon-location",
    name: "QuartersList",
    path: "quarters",
    component: QuartersList,
    meta: { authorize: ["ADMIN", "SEKTOR"] },
  },
  {
    main: false,
    name: "QuarterAdd",
    path: "quarter/add",
    component: QuarterForm,
    meta: { authorize: ["ADMIN", "SEKTOR"] },
  },
  {
    main: false,
    name: "QuarterEdit",
    path: "quarter/edit/:id",
    component: QuarterForm,
    meta: { authorize: ["ADMIN", "SEKTOR"] },
  },

  {
    main: true,
    isSubmenu: false,
    title: "appeals",
    icon: "el-icon-tickets",
    name: "AppealList",
    path: "appeals",
    component: AppealList,
    meta: { authorize: ["SEKTOR"] },
  },

  {
    main: true,
    isSubmenu: false,
    title: "appeals",
    icon: "el-icon-tickets",
    name: "AppealListForAdmin",
    path: "appeals-for-admin",
    component: AppealListForAdmin,
    meta: { authorize: ["ADMIN", "REGION_ADMIN", "SEKTOR_ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "102",
    icon: "el-icon-tickets",
    name: "AppealListForAdmin102",
    path: "appeals-for-102",
    component: AppealListForAdmin102,
    meta: { authorize: ["ADMIN", "REGION_ADMIN", "SEKTOR_ADMIN"] },
  },
  {
    main: false,
    name: "AppealListAdmin",
    path: "appeals-admin",
    component: AppealList,
    meta: { authorize: ["ADMIN", "REGION_ADMIN", "SEKTOR_ADMIN"] },
  },
  {
    main: false,
    name: "ResponseAppeal",
    path: "appeal/response/:id",
    component: ResponseAppeal,
    meta: { authorize: ["ADMIN", "SEKTOR"] },
  },
  {
    main: false,
    name: "Response102",
    path: "102/response/:id",
    component: Response102,
    meta: { authorize: ["ADMIN", "SEKTOR"] },
  },

  {
    main: true,
    isSubmenu: false,
    title: "links",
    icon: "el-icon-link",
    name: "LinkList",
    path: "links",
    component: LinkList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "LinkAdd",
    path: "link/add",
    component: LinkForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "LinkEdit",
    path: "link/edit/:id",
    component: LinkForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "commonAppeals",
    icon: "el-icon-receiving",
    name: "CommonAppeals",
    path: "common-appeal",
    component: CommonAppealList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "CommonAppealAdd",
    path: "common-appeal/add",
    component: CommonAppealForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "CommonAppealEdit",
    path: "common-appeal/edit/:id",
    component: CommonAppealForm,
    meta: { authorize: ["ADMIN"] },
  },

  {
    main: true,
    isSubmenu: false,
    title: "helpList",
    icon: "el-icon-tickets",
    name: "HelpList",
    path: "help-list",
    component: HelpList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "Help",
    path: "help",
    component: Help,
    meta: { authorize: [] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "faqAdmin",
    icon: "el-icon-service",
    name: "FAQ",
    path: "faq",
    component: FaqList,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "FAQAdd",
    path: "faq/add",
    component: FaqForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: false,
    name: "FAQEdit",
    path: "faq/edit/:id",
    component: FaqForm,
    meta: { authorize: ["ADMIN"] },
  },
  {
    main: true,
    isSubmenu: false,
    title: "faq",
    icon: "el-icon-question",
    name: "FaqForUsers",
    path: "faq-user",
    component: FaqForUsers,
    meta: { authorize: ["SEKTOR", "REGION_ADMIN", "SEKTOR_ADMIN", "ADMIN"] },
  },
  {
    main: false,
    name: "ChangeOwnPassword",
    path: "change/password",
    component: UserOwnChangePasswordForm,
    meta: { authorize: [] },
  },
];

export default mainNav;
