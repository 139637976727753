<template>
  <el-select class="lang" size="mini" v-model="lang" placeholder="Lang select">
    <el-option
      v-for="lang in languages"
      :key="lang.value"
      :label="lang.name"
      :value="lang.value"
    >
    </el-option>
  </el-select>
</template>

<script>
import { languages } from "@/i18n/i18n";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      languages: languages
    };
  },
  computed: {
    lang: {
      get: function() {
        return this.$store.state.locale;
      },
      set: function(newVal) {
        this.$store.dispatch("changeLocale", newVal);
      }
    }
  }
};
</script>

<style scoped></style>
