<template>
  <el-card v-loading.lock="loading">
    <div class="header" slot="header">
      <h1>
        {{
          $t("message.change") +
            " " +
            this.$store.state.user.fullName +
            " " +
            $t("message.password")
        }}
      </h1>
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="20" :md="18" :lg="8" :xl="6">
          <el-form
            size="small"
            label-position="top"
            :model="form"
            status-icon
            :rules="rules"
            ref="form"
            label-width="120px"
            class="demo-changePasswordForm"
          >
            <el-form-item
              :label="this.$t('message.oldPassword')"
              prop="oldPassword"
            >
              <el-input
                type="password"
                v-model="form.oldPassword"
                autocomplete="off"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="this.$t('message.newPassword')"
              prop="newPassword"
            >
              <el-input
                type="password"
                v-model="form.newPassword"
                autocomplete="off"
                show-password
                @keyup.enter.native="changePassword"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-form-item>
                <el-button type="primary" size="mini" @click="changePassword">
                  {{ $t("message.save") }}
                </el-button>
                <BackButton>{{ $t("message.back") }}</BackButton>
              </el-form-item>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "UserOwnChangePasswordForm",
  components: { BackButton },
  data() {
    return {
      loading: false,
      form: {
        oldPassword: null,
        newPassword:  null
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: this.$t("message.requiredField"),
            trigger: "blur"
          }
        ],
        newPassword: [
          {
            required: true,
            message: this.$t("message.requiredField"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    changePassword() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .put(
              `simple-user/reset-password`, this.form
            )
            .then(() => {
              this.$message({
                type: "success",
                message: this.$t("message.successfullySaved")
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(response.data.message, this.$t("message.error"), {
                confirmButtonText: this.$t("message.ok"),
                type: "error"
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>
