<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t(`message.${title}`) }}
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="20" :md="18" :lg="12" :xl="10">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  prop="question"
                  :label="this.$t('message.question')"
                >
                  <el-input
                    type="textarea"
                    :rows="5"
                    v-model="form.question"
                    :placeholder="this.$t('message.question')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="answer" :label="this.$t('message.answer')">
                  <el-input
                    type="textarea"
                    :rows="5"
                    v-model="form.answer"
                    :placeholder="this.$t('message.answer')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  prop="appealTypeId"
                  :label="this.$t('message.appealType')"
                >
                  <el-select
                    style="width: 100%"
                    v-model="form.appealTypeId"
                    :placeholder="this.$t('message.appealType')"
                    filterable
                  >
                    <el-option
                      v-for="item in appealTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" align="right">
                <el-form-item>
                  <el-button type="primary" size="mini" @click="save">
                    {{ $t("message.save") }}
                  </el-button>
                  <BackButton>{{ $t("message.back") }}</BackButton>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "CommonAppealForm",
  components: { BackButton },
  data() {
    return {
      loading: true,
      title: "appealAdd",
      baseUrl: "",
      appealTypes: [],
      fileList: [],
      form: {
        id: null,
        question: null,
        appealTypeId: null,
        answer: null,
      },
      rules: {
        question: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        appealTypeId: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        answer: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {

          this.loading = true;
          this.$http
            .post("common-appeal", this.form)
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorLoadingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getOne() {
      this.loading = true;
      this.$http
        .get(`common-appeal/one/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.data.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadAppealTypes() {
      this.loading = true;
      this.$http
        .get("appeal-type")
        .then(({ data }) => {
          this.appealTypes = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.data.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      this.title = "appealEdit";
      this.getOne();
    }
    this.baseUrl = process.env.VUE_APP_BASE_API_URL;
    this.loadAppealTypes();
  },
};
</script>

<style scoped></style>
