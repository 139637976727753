<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.faq") }}
      <linked-button icon="el-icon-question" type="primary" route="Help">{{
        $t("message.help")
      }}</linked-button>
    </div>
    <el-main class="main-wrap">
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="item in data"
          :key="item.id"
          :title="item.question"
          :name="item.id"
        >
          <div>{{ item.description }}</div>
        </el-collapse-item>
      </el-collapse>
    </el-main>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "FaqForUsers",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      activeNames: ["1"],
      data: [],
    };
  },
  methods: {
    loadFaqList() {
      this.loading = false;
      this.$http
        .get("faq", {
          params: {
            userType: this.userType,
            page: this.page,
            size: 50,
          },
        })
        .then(({ data }) => {
          this.data = data.content;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadFaqList();
  },
};
</script>

<style scoped lang="scss">
.main-wrap {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
</style>
