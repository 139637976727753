<template>
  <el-card v-loading.lock="loading">
    <div slot="header">{{ $t(`message.${title}`) }}</div>
    <el-main class="main">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="14">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item :label="this.$t('message.region')">
                  <el-select
                    style="width: 100%"
                    size="mini"
                    v-model="form.regionId"
                    :loading="regionLoading"
                    :loading-text="$t('message.loading')"
                    @change="regionChange"
                    filterable
                  >
                    <el-option
                      v-for="region in regions"
                      :label="region.name"
                      :value="region.id"
                      :key="region.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="this.$t('message.district')">
                  <el-select
                    style="width: 100%"
                    class="district-select"
                    size="mini"
                    :loading="districtLoading"
                    :loading-text="$t('message.loading')"
                    v-model="form.districtId"
                    @change="districtChange"
                    filterable
                  >
                    <el-option
                      v-for="district in districts"
                      :label="district.name"
                      :value="district.id"
                      :key="district.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="divisionId"
                  :label="this.$t('message.division')"
                >
                  <el-select
                    style="width: 100%"
                    size="mini"
                    :loading="divisionLoading"
                    :loading-text="$t('message.loading')"
                    v-model="form.divisionId"
                    filterable
                  >
                    <el-option
                      v-for="division in divisions"
                      :label="division.name"
                      :value="division.id"
                      :key="division.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="mahallaId"
                  :label="this.$t('message.mahalla')"
                >
                  <el-select
                    style="width: 100%"
                    size="mini"
                    :loading="quarterLoading"
                    :loading-text="$t('message.loading')"
                    v-model="form.mahallaId"
                    filterable
                  >
                    <el-option
                      v-for="quarter in quarters"
                      :label="quarter.name"
                      :value="quarter.id"
                      :key="quarter.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="neighborhoodName"
                  :label="this.$t('message.neighborhoodName')"
                >
                  <el-input
                    v-model="form.neighborhoodName"
                    :placeholder="this.$t('message.neighborhoodName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="homeNumber"
                  :label="this.$t('message.homeNumber')"
                >
                  <el-input
                    v-model="form.homeNumber"
                    :placeholder="this.$t('message.homeNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="tpnumber"
                  :label="this.$t('message.tpnumber')"
                >
                  <el-input
                    v-model="form.tpnumber"
                    :placeholder="this.$t('message.tpnumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="tpphoneNumber"
                  :label="this.$t('message.tpphoneNumber')"
                >
                  <el-input
                    v-model="form.tpphoneNumber"
                    :placeholder="this.$t('message.tpphoneNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="organPhoneNumber"
                  :label="this.$t('message.organPhoneNumber')"
                >
                  <el-input
                    v-model="form.organPhoneNumber"
                    :placeholder="this.$t('message.organPhoneNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="firstname"
                  :label="this.$t('message.firstName')"
                >
                  <el-input
                    v-model="form.firstname"
                    :placeholder="this.$t('message.firstName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="lastname"
                  :label="this.$t('message.lastName')"
                >
                  <el-input
                    v-model="form.lastname"
                    :placeholder="this.$t('message.lastName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="middlename"
                  :label="this.$t('message.patronym')"
                >
                  <el-input
                    v-model="form.middlename"
                    :placeholder="this.$t('message.patronym')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="birthDate"
                  :label="this.$t('message.birthDate')"
                >
                  <el-input
                    v-inputmask="'99.99.9999'"
                    v-model="form.birthDate"
                    :placeholder="this.$t('message.birthDate')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="phoneNumber"
                  :label="this.$t('message.phoneNumber')"
                >
                  <el-input
                    v-model="form.phoneNumber"
                    :placeholder="this.$t('message.phoneNumber')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  prop="phoneNumber"
                  :label="this.$t('message.rank')"
                >
                  <el-radio
                    v-model="form.isInspector"
                    :label="true"
                  >{{this.$t('message.inspector')}}</el-radio>
                  <el-radio
                    v-model="form.isInspector"
                    :label="false"
                  >{{this.$t('message.seniorInspector')}}</el-radio>
                </el-form-item>
              </el-col>

              <!--
              <el-col :span="24">
                <el-col :span="18">
                  <el-col :span="24">
                    <el-form-item
                        prop="locationLanLat"
                        :label="this.$t('message.locationLanLat')"
                    >
                      <div>
                        <GmapMap @click="mapClick" :center="center" :zoom="5" class="map" ref="googleMap">
                          <GmapMarker
                              :position="{
                                lat: form.lat,
                                lng: form.lon
                              }"
                          />
                        </GmapMap>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-col>
                <el-col :span="6">
                  <el-col :span="24">
                    <el-form-item
                        prop="lat"
                        :label="this.$t('message.lat')"
                    >
                      <el-input
                          v-model="form.lat"
                          :placeholder="this.$t('message.latlngHover')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item
                        prop="lng"
                        :label="this.$t('message.lng')"
                    >
                      <el-input
                          v-model="form.lon"
                          :placeholder="this.$t('message.latlngHover')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
              </el-col>
              -->

              <el-col :span="24">
                <el-form-item>
                  <el-button type="primary" size="mini" @click="save">
                    {{ $t("message.save") }}
                  </el-button>
                  <BackButton>{{ $t("message.back") }}</BackButton>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "",
    libraries: "places"
  }
});

export default {
  name: "InspectorForm",
  components: { BackButton },
  data() {
    const checkDate = (rule, value, callback) => {
      // console.log(value);
      if (!value) {
        return callback(new Error(this.$t("message.fieldRequired")));
      } else if (value && !this.$moment(value, "DD.MM.YYYY").isValid()) {
        return callback(new Error(this.$t("message.wrongDate")));
      } else {
        callback();
      }
    };
    const checkForLatLng = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("message.fieldRequired")));
      } else if (value && !/^(\+|-)?(?:90(?:(?:\.0{1,15})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(value) ) {
        return callback(new Error(this.$t("message.fieldMustBeLanLat")));
      } else {
        this.form.lat = (typeof this.form.lat === "number") ? this.form.lat : parseFloat(this.form.lat);
        this.form.lon = (typeof this.form.lon === "number") ? this.form.lon : parseFloat(this.form.lon);

        callback();
      }
    };
    return {
      loading: false,
      regionLoading: false,
      districtLoading: false,
      divisionLoading: false,
      quarterLoading: false,
      title: "inspectorAdd",
      regions: [],
      districts: [],
      divisions: [],
      quarters: [],
      center: {
        lat: 41.3775,
        lng: 64.5853
      },
      form: {
        id: null,
        lastname: null,
        firstname: null,
        middlename: null,
        isInspector: true,
        phoneNumber: null,
        organPhoneNumber: null,
        lat: 41.30461398249173,
        lon: 69.26423662101969,
        homeNumber: null,
        tpphoneNumber: null,
        tpnumber: null,
        neighborhoodName: null,
        mahallaId: null,
        divisionId: null,
        birthDate: null,
        regionId: null,
        districtId: null,
      },
      rules: {
        birthDate: [
          {
            validator: checkDate,
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        firstname: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        middlename: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        phoneNumber: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        organPhoneNumber: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        homeNumber: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        tpphoneNumber: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        tpnumber: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        neighborhoodName: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        mahallaId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        divisionId: [
          {
            required: true,
            trigger: "change",
            message: this.$t("message.fieldRequired"),
          },
        ],
        lat: [
          {
            number: true,
            validator: checkForLatLng,
            trigger: "change",
          },
        ],
        lon: [
          {
            number: true,
            validator: checkForLatLng,
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          let form = { ...this.form };

          let http;

          if (this.form.id) {
            http = this.$http.put("inspector", form);
          } else {
            http = this.$http.post("inspector/add", form);
          }

          http
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    regionChange() {
      this.form.divisionId = null;
      this.form.mahallaId = null;
      this.form.districtId = null;
      this.districts = [];
      this.divisions = [];
      this.quarters = [];
      this.loadDistricts();
    },
    districtChange() {
      this.form.divisionId = null;
      this.form.mahallaId = null;
      this.divisions = [];
      this.quarters = [];
      this.loadDivisions();
      this.loadQuarters();
    },
    loadRegions() {
      this.regionLoading = true;
      this.$http
        .get("region")
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.regionLoading = false;
        });
    },
    loadDistricts() {
      this.districtLoading = true;
      this.$http
        .get("district", {
          params: {
            id: this.form.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
          if (this.form.id) {
            this.loadDivisions();
            this.loadQuarters();
          }
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.districtLoading = false;
        });
    },
    loadDivisions() {
      this.divisionLoading = true;
      this.$http
        .get("division", {
          params: {
            id: this.form.districtId,
          },
        })
        .then(({ data }) => {
          this.divisions = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.divisionLoading = false;
        });
    },
    loadQuarters() {
      this.quarterLoading = true;
      this.$http
        .get("mahalla", {
          params: {
            id: this.form.districtId,
          },
        })
        .then(({ data }) => {
          this.quarters = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.quarterLoading = false;
        });
    },
    getInspector() {
      this.loading = true;
      this.$http
        .get(`inspector/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
          this.$nextTick(() => {
            this.loadDistricts();
          });
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapClick({ latLng: { lat, lng } }) {
      this.form.lat = lat();
      this.form.lon = lng();
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "inspectorEdit";
      this.form.id = this.$route.params.id;
      this.getInspector();
    }
    this.loadRegions();
  },
};
</script>

<style scoped></style>
<style>
.map {
  height: 300px;
  width: 100%;
}
</style>