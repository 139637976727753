<template>
  <el-card v-loading.lock="loading">
    <div slot="header">
      {{ $t("message.help") }}
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="14">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <el-form-item prop="text" :label="$t('message.enterYourQuestion')">
              <el-input
                type="textarea"
                v-model="form.text"
                :placeholder="$t('message.enterYourQuestion')"
                :rows="15"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="mini" @click="save(false)">
                {{ $t("message.save") }}
              </el-button>
              <back-button icon="el-icon-back">{{$t("message.back")}}</back-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "Help",
  components: {BackButton},
  data() {
    return {
      loading: false,
      form: {
        text: null,
      },
      rules: {
        text: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("help", this.form, {})
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1)
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
