<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.appeals") }}
      <div>
        <el-date-picker
          v-if="sektorId"
          class="header-item"
          @change="getAppealsBySector"
          :clearable="false"
          size="mini"
          v-model="range"
          type="datetimerange"
          align="right"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="timestamp"
          format="dd.MM.yyyy HH:mm"
          :default-time="['00:00:00', '23:59:00']"
        >
        </el-date-picker>
        <el-select
          class="status-select"
          size="mini"
          v-model="status"
          @change="changeFilter"
          clearable
          :placeholder="$t('message.status')"
          filterable
        >
          <el-option
            v-for="status in statuses"
            :label="$t(`message.${status.name}`)"
            :value="status.value"
            :key="status.value"
          >
          </el-option>
        </el-select>

        <el-select
          class="quarter-select"
          size="mini"
          :loading="quarterLoading"
          :loading-text="$t('message.loading')"
          v-model="quarter"
          :placeholder="$t('message.quarter')"
          @change="changeFilter"
          clearable
          filterable
        >
          <el-option
            v-for="quarter in quarters"
            :label="quarter.name"
            :value="quarter.id"
            :key="quarter.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="main">
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
        height="calc(100vh - 220px)"
        :cell-style="cellStyle"
      >
        <el-table-column type="index" :index="indexMethod"> </el-table-column>
        <el-table-column :label="$t(`message.mahalla`)" prop="mahallaName">
        </el-table-column>
        <el-table-column :label="$t(`message.applicant`)" prop="fullName">
          <template slot-scope="scope">
            <span>{{ scope.row.applicantDto.fullName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :label="$t(`message.birthDate`)"
          prop="birthDate"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.applicantDto.birthDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="110"
          :label="$t(`message.phoneNumber`)"
          prop="phoneNumber"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.applicantDto.phoneNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="70"
          :label="$t(`message.appealTransparency`)"
          prop="appealTransparencyName"
        >
        </el-table-column>
        <el-table-column
          :label="$t(`message.appealType`)"
          prop="appealTypeName"
        >
        </el-table-column>
        <el-table-column
          align="center"
          width="110"
          :label="$t(`message.status`)"
          prop="status"
        >
          <template slot-scope="scope">
            {{ $t(`message.${scope.row.status.toLowerCase()}`) }}
          </template>
        </el-table-column>
        <el-table-column
          width="75"
          :label="$t(`message.createdDate`)"
          prop="createdDate"
        >
          <template slot-scope="scope">
            {{ $moment(scope.row.createdDate).format("DD.MM.YYYY") }}
          </template>
        </el-table-column>
        <!--        <el-table-column :label="$t(`message.content`)" prop="content">-->
        <!--        </el-table-column>-->
        <el-table-column width="90" align="center">
          <template slot-scope="scope">
            <LinkedButton
              route="ResponseAppeal"
              :params="{ id: scope.row.id }"
              type="primary"
              icon="el-icon-view"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElements"
            :page-size="data.size"
            :page-count="data.totalPages"
            :hide-on-single-page="true"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElements > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElements}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "AppealList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      data: [],
      status: null,
      range: [
        this.$moment()
          .clone()
          .startOf("month")
          .hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
        this.$moment().hour(23).minute(59).second(59).valueOf(),
      ],
      statuses: [
        {
          name: "sended",
          value: "SENDED",
        },
        {
          name: "opened",
          value: "OPENED",
        },
        {
          name: "answered",
          value: "ANSWERED",
        },
        {
          name: "rejected",
          value: "REJECTED",
        },
        {
          name: "extended",
          value: "EXTENDED",
        },
      ],
      quarters: [],
      quarter: null,
      quarterLoading: false,
      page: null,
      applicantId: null,
      sektorId: null,
    };
  },
  methods: {
    cellStyle({ row, columnIndex }) {
      // console.log('row: ', row);
      let background = "";
      switch (row.status) {
        case "SENDED":
          background = "#61ca86";
          break;
        case "OPENED":
          background = "#ded14b";
          break;
        case "ANSWERED":
          background = "#b061ca";
          break;
        case "REJECTED":
          background = "#d23d3d";
          break;
        case "EXTENDED":
          background = "#6c6c6c";
          break;
      }
      return columnIndex === 0
          ? {
            backgroundColor: background,
            color: "#fff",
            textAlign: "center",
            fontSize: "15px",
          }
          : {};
    },
    changeFilter() {
      if (this.$route.query.applicant) {
        this.getAppealsByApplicant();
      } else if (this.$route.query.sector) {
        this.getAppealsBySector();
      } else {
        this.getAppeals();
      }
    },
    getQuarters() {
      this.quarterLoading = true;
      this.$http
        .get(`mahalla/by-sektor/${this.sektorId || this.$store.state.user.id}`)
        .then(({ data }) => {
          this.quarters = data;
        })
        .catch(() => {
          // this.$alert(
          //   response.message || this.$t("message.errorLoadingData"),
          //   this.$t("message.error"),
          //   {
          //     confirmButtonText: this.$t("message.ok"),
          //     type: "error",
          //   }
          // );
        })
        .finally(() => {
          this.quarterLoading = false;
        });
    },
    getAppeals() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("appeal/all", {
          params: {
            status: this.status,
            mahallaId: this.quarter,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAppealsByApplicant() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get(`appeal/by-user/${this.applicantId}`, {
          params: {
            status: this.status,
            mahallaId: this.quarter,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAppealsBySector() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get(`admin/appeal`, {
          params: {
            from: this.$moment(this.range[0]).unix(),
            to: this.$moment(this.range[1]).unix(),
            sektorId: this.sektorId,
            status: this.status,
            mahallaId: this.quarter,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      if (this.applicantId) {
        this.$router
          .replace({ query: { page: val, applicant: this.applicantId } })
          .catch(() => {});
        this.getAppealsByApplicant();
      } else if (this.sektorId) {
        this.$router
          .replace({ query: { page: val, sector: this.sektorId } })
          .catch(() => {});
        this.getAppealsBySector();
      } else {
        this.$router.replace({ query: { page: val } }).catch(() => {});
        this.getAppeals();
      }
    },
    indexMethod(index) {
      if (this.data.totalPages > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
    loadAppealsByType() {
      if (this.$route.query.applicant) {
        this.applicantId = this.$route.query.applicant;
        this.getAppealsByApplicant();
      } else if (this.$route.query.sector) {
        this.sektorId = this.$route.query.sector;
        this.getAppealsBySector();
      } else {
        this.getAppeals();
      }
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }

    this.loadAppealsByType();

    this.getQuarters();
  },
};
</script>

<style scoped lang="scss">
.header {
  justify-content: space-between;
  .quarter-select {
    margin-left: 10px;
  }
  .status-select {
    margin-left: 10px;
  }
}
.main {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
.table {
}
.pagination {
  margin-top: 10px;
}
.pagination-total {
  margin-top: 15px;
}
</style>
