import Vue from "vue";
import VueI18n from "vue-i18n";
import { uz } from "./languages/uz.json";
import { ru } from "./languages/ru.json";
import { uz_cyr } from "./languages/uz-cyr.json";

Vue.use(VueI18n);

export const selectedLocale = "uz";

export const languages = [
  { name: "Русский", value: "ru" },
  { name: "O'zbekcha", value: "uz" },
  { name: "Ўзбекча", value: "uz_cyr" },
];
const messages = {
  ru,
  uz,
  uz_cyr,
};

export default new VueI18n({
  locale: selectedLocale,
  messages,
});
