<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      <div>{{ $t("message.regionAdmins") }}</div>
      <div>
        <el-select
          class="header-item"
          size="mini"
          :loading="regionLoading"
          :loading-text="$t('message.loading')"
          v-model="regionId"
          :placeholder="$t('message.region')"
          @change="loadRegionAdmins"
          clearable
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>

        <el-button
          class="header-item"
          size="mini"
          @click="downloadXls"
          type="primary"
          icon="el-icon-document"
          >{{ $t("message.download") }}</el-button
        >
        <linked-button type="success" route="RegionAdd" icon="el-icon-plus">{{
          $t("message.add")
        }}</linked-button>
      </div>
    </div>
    <div class="table-wrap">
      <el-table
        border
        size="mini"
        :data="data"
        style="width: 100%"
        class="table"
      >
        <el-table-column type="index"> </el-table-column>
        <el-table-column :label="$t(`message.firstName`)" prop="firstName">
          <template slot-scope="scope">
            {{ scope.row.firstName + " " + scope.row.lastName }}
          </template>
        </el-table-column>
        <el-table-column :label="$t(`message.phoneNumber`)" prop="phoneNumber">
        </el-table-column>
        <el-table-column :label="$t(`message.username`)" prop="username">
        </el-table-column>
        <el-table-column :label="$t(`message.region`)" prop="regionName">
        </el-table-column>

        <el-table-column width="160" align="center">
          <template slot-scope="scope">
            <el-button-group>
              <LinkedButton
                route="UserChangePassword"
                :params="{ id: scope.row.id }"
                :query="{ username: scope.row.username }"
                icon="el-icon-key"
              />
              <LinkedButton
                route="RegionEdit"
                :params="{ id: scope.row.id }"
                type="primary"
                icon="el-icon-edit"
              />
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="deleteRegionAdmin(scope.row.id)"
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import LinkedButton from "@/components/button/LinkedButton";
import axios from "axios";
export default {
  name: "RegionList",
  components: { LinkedButton },
  data() {
    return {
      loading: false,
      regionLoading: false,
      data: [],
      regions: [],
      regionId: null,
    };
  },
  methods: {
    async downloadXls() {
      this.loading = true;

      const res = await axios
        .get(`${process.env.VUE_APP_BASE_API_URL}admin/region-admin/excel`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${this.$store.state.accessToken}`,
          },
          params: { regionId: this.regionId },
        })
        .then((res) => res)
        .catch(({ response }) => {
          this.$alert(
            response.message || this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
      var blob = new Blob([res.data], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "region-admins.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    loadRegionAdmins() {
      this.loading = true;
      this.$router.replace({
        query: {
          regionId: this.regionId,
        },
      });
      this.$http
        .get("admin/region-admin", { params: { regionId: this.regionId } })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteRegionAdmin(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`admin/region`, { params: { id } })
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadRegionAdmins();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    getRegions() {
      this.regionLoading = true;
      this.$http
        .get(`region`)
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {})
        .finally(() => {
          this.regionLoading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query.regionId) {
      this.regionId = Number.parseInt(this.$route.query.regionId || "", 10);
    }
    this.loadRegionAdmins();
    this.getRegions();
  },
};
</script>

<style scoped lang="scss">
.header-item {
  margin-right: 10px;
}
.table-wrap {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
</style>
