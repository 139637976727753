import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import i18n, { selectedLocale } from "@/i18n/i18n";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    mainSidebarCollapsed: true,
    user: {},
    locale: selectedLocale,
  },
  mutations: {
    authenticate(state, token) {
      state.accessToken = token;
    },
    userData(state, userData) {
      this.state.user = userData;
    },
    signOut(state) {
      state.accessToken = null;
    },

    updateLocale(state, newLocale) {
      this.state.locale = newLocale;
    },
    toggleMainSidebar(state, collapsedSidebar) {
      this.state.mainSidebarCollapsed = collapsedSidebar;
    },
  },
  actions: {
    changeLocale(ctx, newLocale) {
      i18n.locale = newLocale;
      ctx.commit("updateLocale", newLocale);
    },
  },
  plugins: [
    createPersistedState({
      key: "id-problem-solution",
      paths: ["accessToken", "locale", "mainSidebarCollapsed", "user"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
