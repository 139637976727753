<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ $t("message.helpList") }}

      <div class="filter">
        <el-select
          class="header-item"
          size="mini"
          :loading="regionLoading"
          :loading-text="$t('message.loading')"
          v-model="regionId"
          :placeholder="$t('message.region')"
          @change="changeFilter('region')"
          clearable
          filterable
        >
          <el-option
            v-for="region in regions"
            :label="region.name"
            :value="region.id"
            :key="region.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="header-item"
          size="mini"
          :loading="districtLoading"
          :loading-text="$t('message.loading')"
          v-model="districtId"
          :placeholder="$t('message.district')"
          @change="changeFilter('district')"
          clearable
          filterable
        >
          <el-option
            v-for="district in districts"
            :label="district.name"
            :value="district.id"
            :key="district.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="header-item"
          size="mini"
          :loading="sectorLoading"
          :loading-text="$t('message.loading')"
          v-model="sectorId"
          :placeholder="$t('message.sector')"
          @change="changeFilter('sector')"
          clearable
          filterable
        >
          <el-option
            v-for="sector in sectors"
            :label="sector.sektorName"
            :value="sector.id"
            :key="sector.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="header-item"
          size="mini"
          :loading="quarterLoading"
          :loading-text="$t('message.loading')"
          v-model="quarterId"
          :placeholder="$t('message.quarter')"
          @change="changeFilter('quarter')"
          clearable
          filterable
        >
          <el-option
            v-for="quarter in quarters"
            :label="quarter.name"
            :value="quarter.id"
            :key="quarter.id"
          >
          </el-option>
        </el-select>
        <el-select
          class="header-item"
          size="mini"
          v-model="deviceType"
          :placeholder="$t('message.deviceType')"
          @change="loadHelpList"
          filterable
        >
          <el-option
            v-for="type in deviceTypes"
            :label="type"
            :value="type"
            :key="type"
          >
          </el-option>
        </el-select>
        <el-date-picker
          class="header-item"
          @change="loadHelpList"
          :clearable="false"
          size="mini"
          v-model="range"
          type="datetimerange"
          align="right"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="timestamp"
          format="dd.MM.yyyy HH:mm"
          :default-time="['00:00:00', '23:59:00']"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="table-wrap">
      <el-table
        border
        size="mini"
        :data="data.content"
        style="width: 100%"
        class="table"
        height="calc(100vh - 220px)"
      >
        <el-table-column type="index" :index="indexMethod" label="#">
        </el-table-column>
        <el-table-column
          prop="deviceType"
          :label="$t('message.deviceType')"
          width="100"
        ></el-table-column>
        <el-table-column width="180" prop="user" :label="$t('message.user')">
          <template slot-scope="scope">
            {{ scope.row.user.firstName + " " + scope.row.user.lastName }}
          </template>
        </el-table-column>
        <el-table-column
          width="150"
          prop="user"
          :label="$t('message.phoneNumber')"
        >
          <template slot-scope="scope">
            {{ scope.row.user.phoneNumber }}
          </template>
        </el-table-column>
        <el-table-column
          prop="text"
          :label="$t('message.content')"
          class-name="content"
        ></el-table-column>
        <el-table-column width="80" align="center">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="deleteHelp(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col align="right" :span="22">
          <el-pagination
            class="pagination"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="data.totalElements"
            :page-size="data.size"
            :page-count="data.totalPages"
            :hide-on-single-page="true"
            :background="true"
            :current-page="parseInt(this.$route.query.page, 10)"
          >
          </el-pagination>
        </el-col>
        <el-col :span="2">
          <div v-if="data.totalElements > data.size" class="pagination-total">
            <span>{{
              `${this.$t("message.total")}: ${data.totalElements}`
            }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "HelpList",
  data() {
    return {
      loading: false,
      regionLoading: false,
      districtLoading: false,
      sectorLoading: false,
      quarterLoading: false,
      data: [],
      regions: [],
      districts: [],
      sectors: [],
      quarters: [],
      regionId: null,
      districtId: null,
      sectorId: null,
      quarterId: null,
      deviceTypes: ["ANDROID", "WEB", "BOT"],
      deviceType: null,
      range: [
        this.$moment()
          .clone()
          .startOf("month")
          .hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
        this.$moment().hour(23).minute(59).second(59).valueOf(),
      ],
      page: null,
    };
  },
  methods: {
    changeFilter(type) {
      switch (type) {
        case "region":
          this.districts = [];
          this.districtId = null;
          this.sectors = [];
          this.sectorId = null;
          this.quarters = [];
          this.quarterId = null;
          if (this.regionId) {
            this.getDistricts();
          }
          break;
        case "district":
          this.sectors = [];
          this.sectorId = null;
          this.quarters = [];
          this.quarterId = null;
          if (this.districtId) {
            this.getSectors();
          }
          break;
        case "sector":
          this.quarters = [];
          this.quarterId = null;
          if (this.sectorId) {
            this.getQuarters();
          }
          break;
      }
      this.loadHelpList();
    },
    getRegions() {
      this.regionLoading = true;
      this.$http
        .get(`region`)
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {})
        .finally(() => {
          this.regionLoading = false;
        });
    },
    getDistricts(regionId) {
      this.districtLoading = true;
      this.$http
        .get(`district`, {
          params: {
            id: regionId ? regionId : this.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {})
        .finally(() => {
          this.districtLoading = false;
        });
    },
    getSectors() {
      this.sectorLoading = true;
      this.$http
        .get(`sektor-user/byDistrict`, {
          params: {
            districtId: this.districtId,
          },
        })
        .then(({ data }) => {
          this.sectors = this.sortList(data,'sektorName');
        })
        .catch(() => {})
        .finally(() => {
          this.sectorLoading = false;
        });
    },
    getQuarters() {
      this.quarterLoading = true;
      this.$http
        .get(`mahalla/by-sektor/${this.sectorId}`, {})
        .then(({ data }) => {
          this.quarters = data;
        })
        .catch(() => {})
        .finally(() => {
          this.quarterLoading = false;
        });
    },
    loadHelpList() {
      this.loading = true;
      this.page = this.$route.query.page ? this.$route.query.page - 1 : 0;
      this.$http
        .get("help", {
          params: {
            from: this.range[0],
            to: this.range[1],
            regionId: this.regionId,
            districtId: this.districtId,
            deviceType: this.deviceType,
            sectorId: this.sectorId,
            mahallaId: this.quarterId,
            page: this.page,
            size: 20,
          },
        })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteHelp(id) {
      this.$confirm(
        `${this.$t("message.sureToDelete")}`,
        `${this.$t("message.warning")}`,
        {
          confirmButtonText: this.$t("message.yes"),
          cancelButtonText: this.$t("message.no"),
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$http
          .delete(`help/${id}`)
          .then(() => {
            this.$notify({
              title: this.$t("message.successfullyDeleted"),
              type: "success",
            });
            this.loadSectors();
          })
          .catch(() => {
            this.$alert(
              `${this.$t("message.errorProcessing")}`,
              `${this.$t("message.error")}`,
              {
                confirmButtonText: this.$t("message.ok"),
                type: "error",
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$router.replace({ query: { page: val } }).catch(() => {});
      this.loadHelpList();
    },
    indexMethod(index) {
      if (this.data.totalPages > 1 && this.page) {
        return index + this.data.size * (this.$route.query.page - 1) + 1;
      } else {
        return index + 1;
      }
    },
    sortList(data, sortBy, sortedByASC = false) {
      if (sortedByASC) {
        data.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedByASC = false;
      } else {
        data.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedByASC = true;
      }
      return data;
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getRegions();
    this.loadHelpList();
  },
};
</script>
<style lang="scss">
.content {
  //:last-child {
  //  white-space: nowrap !important;
  //  overflow: hidden !important;
  //  text-overflow: ellipsis !important;
  //}
}
</style>
<style scoped lang="scss">
.header {
  justify-content: space-between;
  .filter {
    display: flex;
    .header-item {
      margin-right: 10px;
    }
  }
}
.table-wrap {
  max-height: calc(100vh - 188px);
  overflow-y: auto;
}
</style>
