<template>
  <el-card v-loading.lock="loading">
    <div slot="header" class="header">
      {{ title }}
    </div>

    <el-main class="main">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="24">
          <el-form
            hide-required-asterisk
            size="small"
            class="form"
            :model="form"
            label-position="top"
            ref="form"
            :rules="rules"
          >
            <!-- wrapper : Mahalla asosiy ma'lumotlar  -->
            <el-row :gutter="20">
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item prop="name" :label="this.$t('message.name')">
                  <el-input
                      ref="name"
                      @keyup.enter.native="$refs.regionId.focus()"
                      v-model="form.name"
                      :placeholder="this.$t('message.name')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="regionId"
                    :label="this.$t('message.region')"
                >
                  <el-select
                      ref="regionId"
                      @keyup.enter.native="$refs.districtId.focus()"
                      style="width: 100%"
                      filterable
                      v-model="form.regionId"
                      default-first-option
                      :loading="regionLoading"
                      :loading-text="$t('message.loading')"
                      :placeholder="this.$t('message.region')"
                      @change="regionChange"
                  >
                    <el-option
                        v-for="region in regions"
                        :label="region.name"
                        :value="region.id"
                        :key="region.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="districtId"
                    :label="this.$t('message.district')"
                >
                  <el-select
                      ref="districtId"
                      @keyup.enter.native="$refs.sektorId.focus()"
                      style="width: 100%"
                      v-model="form.districtId"
                      filterable
                      default-first-option
                      :loading="districtLoading"
                      :loading-text="$t('message.loading')"
                      :placeholder="this.$t('message.district')"
                      @change="districtChange"
                  >
                    <el-option
                        v-for="district in districts"
                        :label="district.name"
                        :value="district.id"
                        :key="district.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="sektorId"
                    :label="this.$t('message.sektorId')"
                >
                  <el-select
                      ref="sektorId"
                      @keyup.enter.native="$refs.location.focus()"
                      style="width: 100%"
                      v-model="form.sektorId"
                      filterable
                      default-first-option
                      :loading="districtLoading"
                      :loading-text="$t('message.loading')"
                      :placeholder="this.$t('message.sektorId')"
                      @change="sektorChange"
                  >
                    <el-option
                        v-for="sektor in sektors"
                        :label="sektor.sektorName"
                        :value="sektor.id"
                        :key="sektor.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                    prop="location"
                    :label="this.$t('message.mahallaAddress')"
                >
                  <el-input
                      ref="location"
                      @keyup.enter.native="$refs.mayorFirstName.focus()"
                      v-model="form.location"
                      :placeholder="this.$t('message.mahallaAddress')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Sektor rahbari -->
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="subTitle">
                  {{ this.$t('message.mahallaSektorLeader') }}
                </div>
                <hr />
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormFirstName')"
                >
                  <el-input
                      disabled
                      :value="this.sektorLeader.firstName"
                      :placeholder="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormFirstName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormLastName')"
                >
                  <el-input
                      disabled
                      :value="this.sektorLeader.lastName"
                      :placeholder="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormLastName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormMiddleName')"
                >
                  <el-input
                      disabled
                      :value="this.sektorLeader.middleName"
                      :placeholder="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormMiddleName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaSektorLeader') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                >
                  <el-input
                      disabled
                      :value="this.sektorLeader.phoneNumber"
                      v-inputmask="'99 999 99 99'"
                      :placeholder="'__ ___ __ __'"
                  ><div slot="prepend">+998</div></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Mahalla raisi -->
            <el-row :gutter="20" v-if="form.id">
                <el-col :span="24">
                  <div class="subTitle">
                    {{ this.$t('message.mahallaMayor') }}
                  </div>
                  <hr />
                </el-col>
                <el-col :span="24" :md="12" :lg="12" :xl="6">
                  <el-form-item
                      :label="this.$t('message.mahallaMayor') + ' ' + this.$t('message.fio')"
                  >
                    <el-input
                        disabled
                        ref="mayorFirstName"
                        @keyup.enter.native="$refs.mahallaMayorPhoneNumber.focus()"
                        v-model="form.mayorFirstName"
                        :placeholder="this.$t('message.mahallaMayor') + ' ' + this.$t('message.fio')"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" :md="12" :lg="12" :xl="6">
                  <el-form-item
                      prop="mahallaMayorPhoneNumber"
                      :label="this.$t('message.mahallaMayor') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                  >
                    <el-input
                        disabled
                        ref="mahallaMayorPhoneNumber"
                        @keyup.enter.native="$refs.mahalla_women_leaderfio.focus()"
                        v-inputmask="'99 999 99 99'"
                        v-model="form.mahallaMayorPhoneNumber"
                        :placeholder="this.$t('message.mahallaMayor') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                    ><div slot="prepend">+998</div></el-input
                    >
                  </el-form-item>
                </el-col>
              </el-row>

            <!-- wrapper : MFY xotin qizlar mas'uli -->
            <el-row :gutter="20" v-if="form.id">
              <el-col :span="24">
                <div class="subTitle">
                  {{ this.$t('message.mahallaWomenLeader') }}
                </div>
                <hr />
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaWomenLeader') + ' ' + this.$t('message.fio')"
                >
                  <el-input
                      disabled
                      ref="mahalla_women_leaderfio"
                      @keyup.enter.native="$refs.mahalla_women_leader_phone_number.focus()"
                      v-model="form.mahalla_women_leaderfio"
                      :placeholder="this.$t('message.mahallaWomenLeader') + ' ' + this.$t('message.fio')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaWomenLeader') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                >
                  <el-input
                      disabled
                      ref="mahalla_women_leader_phone_number"
                      @keyup.enter.native="$refs.assistantGovernorLastName.focus()"
                      v-inputmask="'99 999 99 99'"
                      v-model="form.mahalla_women_leader_phone_number"
                      :placeholder="this.$t('message.mahallaWomenLeader') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                  ><div slot="prepend">+998</div></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Hokim yordamchisi -->
            <el-row :gutter="20" v-if="form.id">
              <el-col :span="24">
                <div class="subTitle">
                  {{ this.$t('message.mahallaAssistantHakim') }}
                </div>
                <hr />
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaAssistantHakim') + ' ' + this.$t('message.fio')"
                >
                  <el-input
                      disabled
                      ref="assistantGovernorLastName"
                      @keyup.enter.native="$refs.assistantGovernorPhoneNumber.focus()"
                      v-model="form.assistantGovernorFirstName"
                      :placeholder="this.$t('message.mahallaAssistantHakim') + ' ' + this.$t('message.fio')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaAssistantHakim') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                >
                  <el-input
                      disabled
                      ref="assistantGovernorPhoneNumber"
                      @keyup.enter.native="$refs.profInspectorFirstName.focus()"
                      v-inputmask="'99 999 99 99'"
                      v-model="form.assistantGovernorPhoneNumber"
                      :placeholder="this.$t('message.mahallaAssistantHakim') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                  ><div slot="prepend">+998</div></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Profilaktika inspektori -->
            <el-row :gutter="20" v-if="form.id">
              <el-col :span="24">
                <div class="subTitle">
                  {{ this.$t('message.mahallaProphylaxisInspector') }}
                </div>
                <hr />
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaProphylaxisInspector') + ' ' + this.$t('message.fio')"
                >
                  <el-input
                      disabled
                      ref="profInspectorFirstName"
                      @keyup.enter.native="$refs.profInspectorPhoneNumber.focus()"
                      v-model="form.youngLeaderFirstName"
                      :placeholder="this.$t('message.mahallaProphylaxisInspector') + ' ' + this.$t('message.fio')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaProphylaxisInspector') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                >
                  <el-input
                      disabled
                      ref="profInspectorPhoneNumber"
                      @keyup.enter.native="$refs.youngLeaderFirstName.focus()"
                      v-inputmask="'99 999 99 99'"
                      v-model="form.youngLeaderPhoneNumber"
                      :placeholder="this.$t('message.mahallaProphylaxisInspector') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                  ><div slot="prepend">+998</div></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Yoshlar yetakchisi -->
            <el-row :gutter="20" v-if="form.id">
              <el-col :span="24">
                <div class="subTitle">
                  {{ this.$t('message.mahallaYouthLeader') }}
                </div>
                <hr />
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaYouthLeader') + ' ' + this.$t('message.quarterFormFirstName')"
                >
                  <el-input
                      disabled
                      ref="youngLeaderFirstName"
                      @keyup.enter.native="$refs.youngLeaderPhoneNumber.focus()"
                      v-model="form.profInspectorFirstName"
                      :placeholder="this.$t('message.mahallaYouthLeader') + ' ' + this.$t('message.quarterFormFirstName')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    :label="this.$t('message.mahallaYouthLeader') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                >
                  <el-input
                      disabled
                      ref="youngLeaderPhoneNumber"
                      @keyup.enter.native="$refs.population.focus()"
                      v-inputmask="'99 999 99 99'"
                      v-model="form.profInspectorPhoneNumber"
                      :placeholder="this.$t('message.mahallaYouthLeader') + ' ' + this.$t('message.quarterFormPhoneNumber')"
                  ><div slot="prepend">+998</div></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Raqamlar -->
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="subTitle"></div>
                <hr />
              </el-col>
              <!-- Aholi soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="population"
                    :label="this.$t('message.population')"
                >
                  <el-input
                      ref="population"
                      @keyup.enter.native="$refs.familyCount.focus()"
                      v-model="form.population"
                      disabled
                      :placeholder="this.$t('message.population')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Oila soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="familyCount"
                    :label="this.$t('message.familyCount')"
                >
                  <el-input
                      ref="familyCount"
                      @keyup.enter.native="$refs.apartmentCount.focus()"
                      v-model="form.familyCount"
                      :placeholder="this.$t('message.familyCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Xonadon soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="apartmentCount"
                    :label="this.$t('message.apartmentCount')"
                >
                  <el-input
                      ref="apartmentCount"
                      @keyup.enter.native="$refs.maleCount.focus()"
                      v-model="form.apartmentCount"
                      :placeholder="this.$t('message.apartmentCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Erkaklar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="maleCount"
                    :label="this.$t('message.maleCount')"
                >
                  <el-input
                      ref="maleCount"
                      @keyup.enter.native="$refs.femaleCount.focus()"
                      @input="setPopulation"
                      v-model="form.maleCount"
                      :placeholder="this.$t('message.maleCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Ayollar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="femaleCount"
                    :label="this.$t('message.femaleCount')"
                >
                  <el-input
                      ref="femaleCount"
                      @keyup.enter.native="$refs.minorPopulationCount.focus()"
                      @input="setPopulation"
                      v-model="form.femaleCount"
                      :placeholder="this.$t('message.femaleCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Voyaga yetmaganlar soni (18 yoshgacha) -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="minorPopulationCount"
                    :label="this.$t('message.minorPopulationCount')"
                >
                  <el-input
                      ref="minorPopulationCount"
                      @keyup.enter.native="$refs.teenagerCount.focus()"
                      v-model="form.minorPopulationCount"
                      :placeholder="this.$t('message.minorPopulationCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Yoshlar soni (18-30 yoshgacha) -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="teenagerCount"
                    :label="this.$t('message.teenagerCount')"
                >
                  <el-input
                      ref="teenagerCount"
                      @keyup.enter.native="$refs.unemployedCount.focus()"
                      v-model="form.teenagerCount"
                      :placeholder="this.$t('message.teenagerCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Ishsizlar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="unemployedCount"
                    :label="this.$t('message.unemployedCount')"
                >
                  <el-input
                      ref="unemployedCount"
                      @keyup.enter.native="$refs.underinsuredCount.focus()"
                      v-model="form.unemployedCount"
                      :placeholder="this.$t('message.unemployedCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Kam ta'minlanganlar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="underinsuredCount"
                    :label="this.$t('message.underinsuredCount')"
                >
                  <el-input
                      ref="underinsuredCount"
                      @keyup.enter.native="$refs.troubledFamiliesCout.focus()"
                      v-model="form.underinsuredCount"
                      :placeholder="this.$t('message.underinsuredCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Notinch oilalar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="troubledFamiliesCout"
                    :label="this.$t('message.troubledFamiliesCout')"
                >
                  <el-input
                      ref="troubledFamiliesCout"
                      @keyup.enter.native="$refs.profUchProbCount.focus()"
                      v-model="form.troubledFamiliesCout"
                      :placeholder="this.$t('message.troubledFamiliesCout')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Profilaktik hisobda turuvchilar -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="profUchProbCount"
                    :label="this.$t('message.profUchProbCount')"
                >
                  <el-input
                      ref="profUchProbCount"
                      @keyup.enter.native="$refs.drugAddictsCount.focus()"
                      v-model="form.profUchProbCount"
                      :placeholder="this.$t('message.profUchProbCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- DEO moyil shaxslar -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="drugAddictsCount"
                    :label="this.$t('message.drugAddictsCount')"
                >
                  <el-input
                      ref="drugAddictsCount"
                      @keyup.enter.native="$refs.convictedCount.focus()"
                      v-model="form.drugAddictsCount"
                      :placeholder="this.$t('message.drugAddictsCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Ilgari sudlanganlar -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="convictedCount"
                    :label="this.$t('message.convictedCount')"
                >
                  <el-input
                      ref="convictedCount"
                      @keyup.enter.native="$refs.alcoholPeopleCount.focus()"
                      v-model="form.convictedCount"
                      :placeholder="this.$t('message.convictedCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Spirtli ichimlikka ruju qo'yganlar -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="alcoholPeopleCount"
                    :label="this.$t('message.alcoholPeopleCount')"
                >
                  <el-input
                      ref="alcoholPeopleCount"
                      @keyup.enter.native="$refs.mentallyIllCount.focus()"
                      v-model="form.alcoholPeopleCount"
                      :placeholder="this.$t('message.alcoholPeopleCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Ruhiy kasallar -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="mentallyIllCount"
                    :label="this.$t('message.mentallyIllCount')"
                >
                  <el-input
                      ref="mentallyIllCount"
                      @keyup.enter.native="$refs.ironListMenCount.focus()"
                      v-model="form.mentallyIllCount"
                      :placeholder="this.$t('message.mentallyIllCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Temir daftardagilar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="ironListMenCount"
                    :label="this.$t('message.ironListMenCount')"
                >
                  <el-input
                      ref="ironListMenCount"
                      @keyup.enter.native="$refs.ironListWomenCount.focus()"
                      v-model="form.ironListMenCount"
                      :placeholder="this.$t('message.ironListMenCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Ayollar daftardagilar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="ironListWomenCount"
                    :label="this.$t('message.ironListWomenCount')"
                >
                  <el-input
                      ref="ironListWomenCount"
                      @keyup.enter.native="$refs.ironListYoungCount.focus()"
                      v-model="form.ironListWomenCount"
                      :placeholder="this.$t('message.ironListWomenCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- Yoshlar daftardagilar soni -->
              <el-col :span="24" :md="12" :lg="12" :xl="6">
                <el-form-item
                    prop="ironListYoungCount"
                    :label="this.$t('message.ironListYoungCount')"
                >
                  <el-input
                      ref="ironListYoungCount"
                      @keyup.enter.native="$refs.saveButton.focus()"
                      v-model="form.ironListYoungCount"
                      :placeholder="this.$t('message.ironListYoungCount')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- wrapper : Buttons -->
            <el-row :gutter="20">
              <el-col :span="24" align="right">
                <el-form-item>
                  <el-button
                      ref="saveButton"
                      type="primary"
                      size="mini"
                      @click="saveQuarter"
                  >
                    {{ $t("message.save") }}
                  </el-button>
                  <BackButton>{{ $t("message.back") }}</BackButton>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-card>
</template>

<script>
import BackButton from "@/components/button/BackButton";
export default {
  name: "QuarterForm",
  components: { BackButton },
  computed: {
    title() {
      return this.$route.query.name
        ? this.$route.query.name
        : this.$t("message.addQuarter");
    },
    subTitle() {
      return this.$t("message.quarterLeader");
    },
  },
  data() {
    const checkForNumeric = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error(this.$t("message.fieldRequired")));
      // } else if (value && !/^[0-9]*$/.test(value)) {
      //   return callback(new Error(this.$t("message.fieldMustBeNumric")));
      // } else {
      //   callback();
      // }
      if (value) {
        if (value && !/^[0-9]*$/.test(value)) {
          return callback(new Error(this.$t("message.fieldMustBeNumric")));
        } else {
          callback();
        }
      }
      return true;
    };
    return {
      loading: false,
      regions: [],
      districts: [],
      sektors: [],
      regionLoading: false,
      districtLoading: false,
      sektorLoading: false,

      // Sektor rahbari
      sektorLeader: {
        firstName: null,
        lastName: null,
        middleName: null,
        phoneNumber: null,
      },
      sektorLeaderLoading: false,
      form: {
        // quarter main information
        id: null,
        name: null,
        regionId: null,
        districtId: null,
        sektorId: null,
        location: null,

        // Mahalla raisi
        mayorFirstName: null,
        mayorLastName: null,
        mayorMiddleName: null,
        mahallaMayorPhoneNumber: null,
        mahallaMayorPhoneNumberSecond: null,

        // MFY xotin qizlar mas'uli
        mahalla_women_leaderfio: null,
        mahalla_women_leader_phone_number: null,
        womenLeaderFirstName: null,
        womenLeaderLastName: null,
        womenLeaderMiddleName: null,
        womenLeaderPhoneNumber: null,
        womenLeaderPhoneNumberSecond: null,

        // Hokim yordamchisi
        assistantGovernorFirstName: null,
        assistantGovernorLastName: null,
        assistantGovernorMiddleName: null,
        assistantGovernorPhoneNumber: null,
        assistantGovernorPhoneNumberSecond: null,

        // Profilaktika inspektori
        profInspectorFirstName: null,
        profInspectorLastName: null,
        profInspectorMiddleName: null,
        profInspectorPhoneNumber: null,
        profInspectorPhoneNumberSecond: null,

        // Yoshlar yetakchisi
        youngLeaderFirstName: null,
        youngLeaderLastName: null,
        youngLeaderMiddleName: null,
        youngLeaderPhoneNumber: null,
        youngLeaderPhoneNumberSecond: null,

        // Aholi soni
        population: null,
        // Oila soni
        familyCount: null,
        // Xonadon soni
        apartmentCount: null,
        // Erkaklar soni
        maleCount: null,
        // Ayollar soni
        femaleCount: null,
        // Voyaga yetmaganlar soni (18 yoshgacha)
        minorPopulationCount: null,
        // Yoshlar soni (18-30 yoshgacha)
        teenagerCount: null,
        // Ishsizlar soni
        unemployedCount: null,
        // Kam ta'minlanganlar soni
        underinsuredCount: null,
        // Notinch oilalar soni
        troubledFamiliesCout: null,
        // Profilaktik hisobda turuvchilar
        profUchProbCount: null,
        // DEO moyil shaxslar
        drugAddictsCount: null,
        // Ilgari sudlanganlar
        convictedCount: null,
        // Spirtli ichimlikka ruju qo'yganlar
        alcoholPeopleCount: null,
        // Ruhiy kasallar
        mentallyIllCount: null,
        // Temir daftardagilar soni
        ironListMenCount: null,
        // Ayollar daftardagilar soni
        ironListWomenCount: null,
        // Yoshlar daftardagilar soni
        ironListYoungCount: null,
    subTitle: "",
      },
      rules: {
        regionId: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        districtId: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        sektorId: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        location: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],

        mayorFirstName: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        mayorLastName: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        mayorMiddleName: [
          {
            required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],

        mahalla_women_leader_phone_number: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        womenLeaderFirstName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        womenLeaderLastName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        womenLeaderMiddleName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],

        assistantGovernorFirstName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        assistantGovernorLastName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        assistantGovernorMiddleName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],

        youngLeaderFirstName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        youngLeaderLastName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        youngLeaderMiddleName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],

        profInspectorFirstName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        profInspectorLastName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],
        profInspectorMiddleName: [
          {
            // required: true,
            message: this.$t("message.fieldRequired"),
            trigger: "change",
          },
        ],

        population: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        familyCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        apartmentCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        maleCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        femaleCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        minorPopulationCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        teenagerCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        unemployedCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        underinsuredCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        troubledFamiliesCout: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        profUchProbCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        drugAddictsCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        convictedCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        alcoholPeopleCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        mentallyIllCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        ironListMenCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        ironListWomenCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
        ironListYoungCount: [
          {
            validator: checkForNumeric,
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    saveQuarter() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          this.form.mahallaMayorPhoneNumber = this.form.mahallaMayorPhoneNumber
            ? this.form.mahallaMayorPhoneNumber.replace(/\s/g, "")
            : null;

          this.form.womenLeaderPhoneNumber = this.form.womenLeaderPhoneNumber
              ? this.form.womenLeaderPhoneNumber.replace(/\s/g, "")
              : null;

          this.form.mahalla_women_leader_phone_number = this.form.mahalla_women_leader_phone_number
              ? this.form.mahalla_women_leader_phone_number.replace(/\s/g, "")
              : null;

          this.form.assistantGovernorPhoneNumber = this.form.assistantGovernorPhoneNumber
              ? this.form.assistantGovernorPhoneNumber.replace(/\s/g, "")
              : null;

          this.form.youngLeaderPhoneNumber = this.form.youngLeaderPhoneNumber
              ? this.form.youngLeaderPhoneNumber.replace(/\s/g, "")
              : null;

          this.form.profInspectorPhoneNumber = this.form.profInspectorPhoneNumber
              ? this.form.profInspectorPhoneNumber.replace(/\s/g, "")
              : null;

          let http;
          if (this.form.id) {
            http = this.$http.put("mahalla", this.form);
          } else {
            http = this.$http.post("mahalla", this.form);
          }
          http
            .then(() => {
              this.$notify({
                title: this.$t("message.successfullySaved"),
                type: "success",
              });
              this.$router.go(-1);
            })
            .catch(({ response }) => {
              this.$alert(
                response.data.message || this.$t("message.errorSavingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getQuarter() {
      this.loading = true;
      this.$http
          .get(`mahalla/${this.form.id}`)
          .then(async ({data}) => {
            this.form = data;
            await this.getRegions();
            await this.getDistricts();
            await this.getSektors();
            await this.sektorChange();
          })
          .catch(() => {
            this.$alert(
                this.$t("message.errorLoadingData"),
                this.$t("message.error"),
                {
                  confirmButtonText: this.$t("message.ok"),
                  type: "error",
                }
            );
          })
          .finally(() => {
            this.loading = false;
          });
    },
    regionChange() {
      this.form.districtId = null;
      this.districts = [];
      this.getDistricts();
    },
    districtChange() {
      this.form.sektorId = null;
      this.sektors = [];
      this.getSektors();
    },
    sektorChange() {
      this.sektorLeader = {
        firstName: null,
        lastName: null,
        middleName: null,
        phoneNumber: null,
      }
      this.setSektorLeader();
    },
    getRegions() {
      this.regionLoading = true;
      this.$http
        .get("region")
        .then(({ data }) => {
          this.regions = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.regionLoading = false;
        });
    },
    getDistricts() {
      this.districtLoading = true;
      this.$http
        .get("district", {
          params: {
            id: this.form.regionId,
          },
        })
        .then(({ data }) => {
          this.districts = data;
        })
        .catch(() => {
          this.$alert(
            this.$t("message.errorLoadingData"),
            this.$t("message.error"),
            {
              confirmButtonText: this.$t("message.ok"),
              type: "error",
            }
          );
        })
        .finally(() => {
          this.districtLoading = false;
        });
    },
    getSektors() {
      this.sektorLoading = true;
      this.$http
          .get("sektor-user/byDistrict", {
            params: {
              districtId: this.form.districtId,
            },
          })
          .then(({ data }) => {
            this.sektors = this.sortList(data,'sektorName');
            /*
            this.sektors = data.map((item) => {
              item.username = item.username.replace(/_.*_/, "");
              return item;
            });
            */
          })
          .catch(() => {})
          .finally(() => {
            this.sektorLoading = false;
          });
    },
    setSektorLeader() {
      this.sektorLeaderLoading = true;
      if (this.form.sektorId) {
        this.$http
            .get("sektor-user/" + this.form.sektorId, )
            .then(({ data }) => {
                this.sektorLeader = data;
            })
            .catch(() => {})
            .finally(() => {
              this.sektorLoading = false;
            });
      }
    },
    setPopulation() {
      this.form.population =
        this.form.maleCount && this.form.femaleCount
          ? parseInt(this.form.maleCount) + parseInt(this.form.femaleCount)
          : null;
    },
    sortList(data, sortBy, sortedByASC = false) {
      if (sortedByASC) {
        data.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        sortedByASC = false;
      } else {
        data.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        sortedByASC = true;
      }
      return data;
    },
  },
   async mounted() {
     if (this.$route.params.id) {
       this.form.id = this.$route.params.id;
       this.form.regionId = this.$route.params.regionId;
       this.form.districtId = this.$route.params.districtId;
       this.form.sektorId = this.$route.params.sektorId;
       this.getQuarter();
       this.$refs.name.focus();
     } else {
       this.getRegions();
       this.$refs.name.focus();
     }
   },
  created() {
    window.addEventListener("keypress", (e) => {
      if (e.ctrlKey) {
        this.saveQuarter();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("keypress", () => {});
  },
};
</script>

<style scoped lang="scss">
.main {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.header {
  font-weight: bolder;
}
.subTitle {
  font-weight: bolder;
  margin: 20px 0;
}
hr {
  border: none;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
}
</style>
