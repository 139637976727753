<template>
  <el-container class="main-container">
    <el-aside class="main-sidebar" :class="{ collapsed: collapsedSidebar }">
      <div class="main-sidebar-title" :class="{ collapsed: collapsedSidebar }">
        <img
          v-if="collapsedSidebar"
          src="../assets/images/auth-logo.png"
          alt="logo"
        />
        <div v-else class="collapsed-logo">
          <img src="../assets/images/auth-logo.png" alt="IIB-smart" />
          <div class="collapsed-text">IIB-SMART</div>
        </div>
      </div>
      <main-navigation :collapsed="collapsedSidebar"></main-navigation>
    </el-aside>
    <el-container>
      <el-header class="main-header" height="60px">
        <div class="to-left">
          <el-button
            type="text"
            :icon="menuIcon"
            @click="onSidebarToggle"
          ></el-button>
        </div>
        <div class="to-right">
          <LocaleSwitcher />
          <el-dropdown @command="onHeaderDropdownCommand">
            <span class="el-dropdown-link">
              <i class="el-icon-user"></i>
              {{ $store.state.user.username }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <linked-button
                  route="ChangeOwnPassword"
                  type="text"
                  icon="el-icon-key"
                >
                  {{ $t("message.changePassword") }}
                </linked-button>
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-back" command="sign-out">
                {{ $t("message.signOut") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <div class="main-content">
        <div class="main-content-wrapper">
          <router-view />
        </div>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import LinkedButton from "@/components/button/LinkedButton";
export default {
  name: "Main",
  components: { LinkedButton, LocaleSwitcher, MainNavigation },
  computed: {
    menuIcon() {
      return this.collapsedSidebar ? "el-icon-s-unfold" : "el-icon-s-fold";
    },
  },
  data() {
    return {
      collapsedSidebar: this.$store.state.mainSidebarCollapsed,
    };
  },
  methods: {
    signOut() {
      this.$confirm(this.$t("message.sureToExit"), this.$t("message.signOut"), {
        confirmButtonText: this.$t("message.yes"),
        cancelButtonText: this.$t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$store.commit("signOut");
          this.$router.push("/auth");
        })
        .catch(() => {});
    },
    onSidebarToggle() {
      this.collapsedSidebar = !this.collapsedSidebar;
      this.$store.commit("toggleMainSidebar", this.collapsedSidebar);
    },
    onHeaderDropdownCommand(command) {
      switch (command) {
        case "sign-out": {
          this.signOut();
          break;
        }
      }
    },
  },
  created() {
    this.$store.dispatch("changeLocale", this.$store.state.locale);
  },
};
</script>

<style scoped lang="scss">
$wrapper-padding: 18px;
$header-item-margin: 16px;
.main {
  &-container {
    width: 100%;
    height: 100%;
    background-color: #f5f6fa;
  }
  &-header {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(43, 45, 55, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;

    .to-left > * {
      margin-right: $header-item-margin;
    }
    .to-right > * {
      margin-left: $header-item-margin;
      .el-dropdown-link {
        cursor: pointer;
        color: #2a3098;
      }
    }
  }
  &-sidebar {
    background-color: #fff;
    box-shadow: 4px 0 4px 0 rgba(43, 45, 55, 0.03);
    z-index: 1000;
    width: 300px;
    &.collapsed {
      width: 64px !important;
    }
    &-title {
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: center;
      > .collapsed-logo {
        display: flex;
        height: 100%;
        width: 70%;
        align-items: center;
        justify-content: space-around;
        > img {
          height: 50px;
        }
        > .collapsed-text {
          font-size: xx-large;
          font-weight: bolder;
          color: #3f3f58;
          text-align: center;
        }
      }
      &.collapsed img {
        height: 32px;
      }
    }
  }
  &-content {
    height: calc(100vh - 60px);
    position: relative;
    &-wrapper {
      position: absolute;
      left: $wrapper-padding;
      right: $wrapper-padding;
      top: $wrapper-padding;
      bottom: $wrapper-padding;
      & > .el-card {
        min-height: 100%;
      }
    }
  }
}
</style>
